import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { OverviewPage } from '@coachmate/overview'
import { Redirect, Route } from '@coachmate/router'

export const OverviewLayout = memo(() => (
  <Switch>
    <Route path="/overview" exact>
      <OverviewPage />
    </Route>
    <Redirect to="/overview" />
  </Switch>
))
