import classNames from 'classnames'
import React, { ReactNode, HTMLProps, memo } from 'react'
import { componentService } from '@coachmate/common'

type Props = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode
  className?: string
}

export const Th = memo(({ className, children, ...rest }: Props) => {
  const classes = classNames(
    className,
    'font-medium text-left text-xs tracking-wider whitespace-nowrap uppercase px-4 py-3 first:pl-0 last:pr-0',
    componentService.getTextClasses('primary-2')
  )

  return (
    <th {...rest} className={classes}>
      {children}
    </th>
  )
})
