import React, { memo } from 'react'
import classNames from 'classnames'
import { Text } from '@coachmate/common'
import { GqlCoreTagFieldsFragment } from '@graphql'

type Props = {
  className?: string
  tag: GqlCoreTagFieldsFragment
}

const BASE_CLASSES = ['inline-flex', 'items-center', 'justify-center', 'rounded-full', 'px-2', 'py-0.5']

export const Tag = memo(({ className, tag }: Props) => {
  const classes = classNames(className, BASE_CLASSES)

  return (
    <div className={classes} style={{ backgroundColor: tag.colour }}>
      <Text className="text-xs truncate" variant="primary-1">
        {tag.value}
      </Text>
    </div>
  )
})
