import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@coachmate/router'
import { LoginPage, LogoutPage } from '@coachmate/auth'

export const AuthLayout = memo(() => (
  <Switch>
    <Route path="/login" exact>
      <LoginPage />
    </Route>
    <Route path="/logout" exact>
      <LogoutPage />
    </Route>
  </Switch>
))
