import React from 'react'
import { Redirect as ReactRedirect, RedirectProps } from 'react-router-dom'
import { RouterPath } from '@coachmate/router'

type Props = Omit<RedirectProps, 'from' | 'to'> & {
  from?: RouterPath
  to: RouterPath
}

export const Redirect = (props: Props) => <ReactRedirect {...props} />
