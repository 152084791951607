import { GqlStatus } from '@graphql'
import { SelectOption } from '@coachmate/common'

export type UserListFilterFormData = {
  firstName: string
  lastName: string
  email: string
  tagId: string
  entityId: string
  isVerified: UserListIsVerifiedOption
  isRequestAccess: boolean
  status: GqlStatus
}

export type UserListIsVerifiedOption = 'both' | 'yes' | 'no'

export const USER_LIST_IS_VERIFIED_OPTIONS: SelectOption<UserListIsVerifiedOption>[] = [
  {
    label: 'Yes and No',
    value: 'both',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const USER_LIST_IS_ACTIVE_OPTIONS: SelectOption<GqlStatus>[] = [
  {
    label: 'Yes',
    value: 'active',
  },
  {
    label: 'No',
    value: 'inactive',
  },
]

export const BASE_FILTER_FORM_DATA: UserListFilterFormData = {
  firstName: '',
  lastName: '',
  email: '',
  entityId: '',
  tagId: '',
  isVerified: 'both',
  status: 'active',
  isRequestAccess: false,
}
