import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionUserIntention = memo(({ overview }: Props) => {
  const userIntentions = [
    { label: 'Coach', count: 0, percentage: 0 },
    { label: 'Parent', count: 0, percentage: 0 },
    { label: 'Player', count: 0, percentage: 0 },
  ]

  let totalUserIntentionCount = 0
  forEach(overview.userIntentions, ({ count }) => {
    totalUserIntentionCount += count
  })

  forEach(overview.userIntentions, ({ intention, count }) => {
    if (intention === 'coach') {
      userIntentions[0].count = count
      userIntentions[0].percentage = (count / totalUserIntentionCount) * 100
    } else if (intention === 'parent') {
      userIntentions[1].count = count
      userIntentions[1].percentage = (count / totalUserIntentionCount) * 100
    } else if (intention === 'player') {
      userIntentions[2].count = count
      userIntentions[2].percentage = (count / totalUserIntentionCount) * 100
    }
  })

  return (
    <OverviewCard className="mt-5" heading="User intention">
      <OverviewBreakdownBars className="mt-5" breakdown={userIntentions} />
    </OverviewCard>
  )
})
