import { map } from 'lodash'
import React, { memo, useEffect } from 'react'
import { useUser } from '@coachmate/user'
import { CreateTagModal, TagListRow, useTag } from '@coachmate/tag'
import {
  Button,
  FourOhFourPage,
  Page,
  Section,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useAnalytics,
  useModal,
} from '@coachmate/common'

export const TagListPage = memo(() => {
  const { tags } = useTag()
  const { track } = useAnalytics()
  const { openModal } = useModal()
  const { isSuperuser } = useUser()

  useEffect(() => {
    track('admin_tag_list_view')
  }, [])

  if (!isSuperuser) {
    return <FourOhFourPage />
  }

  const renderContent = () => {
    if (!tags.length) {
      return (
        <Tbody>
          <Tr>
            <Td colSpan={3}>
              <Text className="text-sm text-center" variant="primary-3">
                No results found
              </Text>
            </Td>
          </Tr>
        </Tbody>
      )
    }

    return (
      <Tbody>
        {map(tags, (tag) => (
          <TagListRow key={tag.id} tag={tag} />
        ))}
      </Tbody>
    )
  }

  return (
    <Page>
      <Section className="pt-16 pb-5">
        <div className="flex items-center justify-end">
          <Button variant="primary" onClick={() => openModal(<CreateTagModal />)}>
            Create tag
          </Button>
        </div>
        <div className="border border-ui-800 rounded-lg p-5 mt-8">
          <TableContainer>
            <Table>
              <Thead>
                <Th className="w-1">Tag</Th>
                <Th>Description</Th>
                <Th className="w-1" />
              </Thead>
              {renderContent()}
            </Table>
          </TableContainer>
        </div>
      </Section>
    </Page>
  )
})
