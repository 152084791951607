import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { NumberAnimation, Text, utilService } from '@coachmate/common'
import { OverviewCard, OverviewPastFutureBars } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionGameEventsCreated = memo(({ overview }: Props) => (
  <OverviewCard className="mt-5" heading="Game events created">
    <Text className="text-4xl mt-2 mb-5">
      <NumberAnimation value={overview.gameEventsCreated} format={utilService.formatInt} duration={0.5} />
    </Text>
    <OverviewPastFutureBars past={overview.gameEventsCreatedPast} future={overview.gameEventsCreatedFuture} />
  </OverviewCard>
))
