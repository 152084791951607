import classNames from 'classnames'
import React, { ReactNode, MouseEvent as ReactMouseEvent, memo, AnchorHTMLAttributes } from 'react'
import { Button } from '@coachmate/common'

type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = {
  children: ReactNode
  className?: string
  to?: string
  onClick?: AnchorClickEvent | ButtonClickEvent
  isDisabled?: boolean
  isExternal?: boolean
  isOpenedInNewTab?: boolean
}

const BASE_CLASSES = [
  'relative',
  'block',
  'border-b',
  'border-ui-500',
  'outline-none',
  'w-full',
  'whitespace-nowrap',
  'px-4',
  'py-3',
  'first:rounded-t-md',
  'last:rounded-b-md',
]

export const DropdownContent = memo(({ children, className, to, onClick, isDisabled, isExternal, isOpenedInNewTab }: Props) => {
  const classes = classNames(className, BASE_CLASSES, {
    'cursor-pointer hover:opacity-80': onClick || to,
    'pointer-events-none opacity-50': isDisabled,
  })

  if (to) {
    if (isExternal) {
      const additionalAttrs: AnchorHTMLAttributes<HTMLAnchorElement> = {}

      if (isOpenedInNewTab) {
        additionalAttrs.target = '_blank'
        additionalAttrs.rel = 'noreferrer'
      }

      return (
        <a {...additionalAttrs} className={classes} href={to} onClick={onClick as AnchorClickEvent}>
          {children}
        </a>
      )
    }

    return (
      <Button className={classes} href={to} onClick={onClick as ButtonClickEvent} state="text" isLink>
        {children}
      </Button>
    )
  }

  if (onClick) {
    return (
      <button className={classes} onClick={onClick as ButtonClickEvent} type="button">
        {children}
      </button>
    )
  }

  return <div className={classes}>{children}</div>
})
