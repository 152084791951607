import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Text } from '@coachmate/common'

type Props = {
  className?: string
  children: ReactNode
  heading: string
}

export const OverviewCard = memo(({ className, children, heading }: Props) => (
  <div className={classNames(className, 'border border-ui-700 rounded-3xl w-full px-8 py-10')}>
    <Text className="font-semibold" variant="primary-3">
      {heading}
    </Text>
    {children}
  </div>
))
