import React from 'react'
import { Text, Page, Button } from '@coachmate/common'
import { routerService } from '@coachmate/router'

export const AppErrorPage = () => (
  <Page isCentered isFullScreen>
    <div className="text-center">
      <Text className="text-center text-xl font-bold">Uh oh!</Text>

      <Text className="text-center mt-2 mb-4">Something unexpected happened.</Text>

      <Button href={routerService.getHref('/logout')} state="text" isLink>
        Log out
      </Button>
    </div>
  </Page>
)
