import { gql } from '@apollo/client'

export const TAG_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreTagFields on Tag {
    id
    value
    description
    colour
  }
`

export const USER_TAG_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreUserTagFields on UserTag {
    id
    userId
    value
    description
    colour
  }
`
