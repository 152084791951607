import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route } from '@coachmate/router'
import { UserListPage, UserViewPage, UserListRequestAccessPage } from '@coachmate/user'

export const UserLayout = memo(() => (
  <Switch>
    <Route path="/user/list" exact>
      <UserListPage />
    </Route>
    <Route path="/user/request-access-list" exact>
      <UserListRequestAccessPage />
    </Route>
    <Route path="/user/view/:userId" exact>
      <UserViewPage />
    </Route>
    <Redirect from="/user" to="/user/list" />
  </Switch>
))
