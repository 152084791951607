import { find, map } from 'lodash'
import React, { FormEvent, useEffect, useState } from 'react'
import { useUser } from '@coachmate/user'
import { useEntity } from '@coachmate/entity'
import { GqlCoreAdminUserFieldsFragment } from '@graphql'
import { superuserUpdateAccount } from '@coachmate/superuser'
import {
  Button,
  Checkbox,
  ERROR_MESSAGE,
  Section,
  Select,
  SelectOption,
  sentryService,
  Text,
  useAnalytics,
  useModal,
  useRunQuery,
  useToast,
} from '@coachmate/common'

type Props = {
  adminUser: GqlCoreAdminUserFieldsFragment
  refetch: () => void
}

export const ManageUserAccountsModal = ({ adminUser, refetch }: Props) => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { entities } = useEntity()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const { isSuperuser } = useUser()

  const [isAdmin, setIsAdmin] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState<string>()

  useEffect(() => {
    track('admin_user_manage_accounts_modal_open', { userId: adminUser.user.id })

    if (!adminUser.accounts.length) {
      closeModal()
      openToast(<Text>User has no accounts</Text>, 'danger')
      return
    }

    if (!isSuperuser) {
      closeModal()
      openToast(<Text>{ERROR_MESSAGE.permission}</Text>, 'danger')
      return
    }

    setSelectedAccountId(adminUser.accounts[0].id)
    setIsAdmin(adminUser.accounts[0].isAdmin)
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selectedAccountId) {
      return
    }

    track('admin_user_manage_accounts_submit', { userId: adminUser.user.id, accountId: selectedAccountId, isAdmin })
    setIsSaving(true)

    try {
      await runQuery(async () => {
        await superuserUpdateAccount({ accountId: selectedAccountId, isAdmin })
        refetch()
        closeModal()
      })
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      track('admin_user_manage_accounts_submit_error', { userId: adminUser.user.id, accountId: selectedAccountId, isAdmin, error: error.message })
      sentryService.captureException({ exception: error, extras: { userId: adminUser.user.id, accountId: selectedAccountId, isAdmin } })
    }
  }

  const handleAccountChange = (accountId: string) => {
    const account = find(adminUser.accounts, { id: accountId })
    setSelectedAccountId(accountId)
    setIsAdmin(account?.isAdmin || false)
  }

  const accountOptions: SelectOption[] = map(adminUser.accounts, ({ id, entityId }) => {
    const entity = find(entities, { id: entityId })

    return { label: entity?.name || 'Unknown', value: id }
  })

  if (!accountOptions.length) {
    return null
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Text className="font-semibold text-lg mb-4">Entity</Text>
        <Select options={accountOptions} onChange={handleAccountChange} value={selectedAccountId} isDisabled={isSaving} />
        <label htmlFor="manage-admin-entities-checkbox" className="flex flex-row items-center cursor-pointer mt-5">
          <Checkbox
            id="manage-admin-entities-checkbox"
            className="mr-2"
            onChange={() => setIsAdmin(!isAdmin)}
            isChecked={isAdmin}
            isDisabled={isSaving}
          />
          <Text>Is Admin</Text>
        </label>
        <Text className="text-xs text-left mt-2" variant="primary-3">
          By checking this box, the user will be able to log into the CoachMate Admin portal, and view all stats, users, teams, and content for this
          entity.
        </Text>
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isSaving}>
            Update
          </Button>
        </div>
      </Section>
    </form>
  )
}
