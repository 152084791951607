import { Route, RouteProps, useHistory } from 'react-router-dom'
import React, { createElement, ComponentType, memo, useEffect, useState } from 'react'
import { useTag } from '@coachmate/tag'
import { useAuth } from '@coachmate/auth'
import { useUser } from '@coachmate/user'
import { useEntity } from '@coachmate/entity'
import { AppErrorPage } from '@coachmate/app'
import { adminContext } from '@coachmate/admin'
import { routerService } from '@coachmate/router'
import { analyticsService, loggerService, sentryService, useAnalytics, useRunQuery } from '@coachmate/common'

type Props = RouteProps & {
  component: ComponentType<any>
}

export const AppRoute = memo(({ component, ...rest }: Props) => {
  const history = useHistory()
  const { setTags } = useTag()
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { setUser, setIsSuperuser, setHasRequestAccess } = useUser()
  const [isError, setIsError] = useState(false)
  const { setEntities, setEntity } = useEntity()
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticating, isAuthenticated, firebaseUser } = useAuth()

  useEffect(() => {
    ;(async () => {
      try {
        if (isAuthenticating) {
          return
        }

        if (!isAuthenticated || !firebaseUser) {
          loggerService.debug('[<AppRoute />] User is not authenticated. Redirecting to login.')
          return history.push(routerService.getHref('/login'))
        }

        await runQuery(async () => {
          const { user, entities, tags, isSuperuser, entityAccessRequestCount } = await adminContext()

          if (!isSuperuser && !entities.length) {
            setIsError(true)
            setIsLoading(false)
            return
          }

          const { id: userId } = user
          sentryService.setUser(userId)
          analyticsService.identify(user)

          setTags(tags)
          setUser(user)
          setEntities(entities)
          setEntity(isSuperuser ? null : entities[0])
          setIsSuperuser(isSuperuser)
          setHasRequestAccess(Boolean(entityAccessRequestCount > 0))
          setIsLoading(false)
          track('admin_app_context_load')
        })
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        setIsError(true)
        setIsLoading(false)
        track('admin_app_context_load_error')
      }
    })()
  }, [isAuthenticating])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return null
        }

        if (isError) {
          return <AppErrorPage />
        }

        return createElement(component, props)
      }}
    />
  )
})
