import { TAG_PATHS } from '../tag/tag.routes'
import { AUTH_PATHS } from '../auth/auth.routes'
import { USER_PATHS } from '../user/user.routes'
import { OVERVIEW_PATHS } from '../overview/overview.routes'

export type RouterPath =
  | '/'
  | '/error'
  | typeof TAG_PATHS[number]
  | typeof AUTH_PATHS[number]
  | typeof USER_PATHS[number]
  | typeof OVERVIEW_PATHS[number]

export const ROUTES = [...AUTH_PATHS, ...OVERVIEW_PATHS, ...USER_PATHS, ...TAG_PATHS] as const
