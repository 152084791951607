import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlUpdateTagInput, GqlUpdateTagMutation, GqlUpdateTagMutationVariables } from '@graphql'

const UPDATE_TAG = gql`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
    }
  }
`

export const updateTag = async (input: GqlUpdateTagInput) => {
  loggerService.debug('[updateTag] Updating tag.')

  const { data } = await apolloService.mutate<GqlUpdateTagMutation, GqlUpdateTagMutationVariables>({
    mutation: UPDATE_TAG,
    variables: { input },
  })

  return data?.updateTag
}
