import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { ADMIN_USER_CORE_FIELDS_FRAGMENT } from './admin-user-fragment.graphql'
import { GqlAdminUserViewInput, GqlAdminUserViewQuery, GqlAdminUserViewQueryVariables } from '@graphql'

export const ADMIN_USER_VIEW = gql`
  ${ADMIN_USER_CORE_FIELDS_FRAGMENT}

  query AdminUserView($input: AdminUserViewInput!) {
    adminUserView(input: $input) {
      ...CoreAdminUserFields
    }
  }
`

export const adminUserView = async (input: GqlAdminUserViewInput) => {
  loggerService.debug('[adminUserView] Get admin users.')

  const { data } = await apolloService.query<GqlAdminUserViewQuery, GqlAdminUserViewQueryVariables>({
    query: ADMIN_USER_VIEW,
    variables: { input },
    fetchPolicy: 'network-only',
  })

  return data.adminUserView
}
