import React, { memo } from 'react'
import { forEach, map, sortBy, split } from 'lodash'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionTeamAge = memo(({ overview }: Props) => {
  if (!overview.teamAges.length || overview.teamAges.length > 4) {
    return null
  }

  let totalTeamAgeCount = 0
  forEach(overview.teamAges, ({ count }) => {
    totalTeamAgeCount += count
  })

  const teamAges = map(overview.teamAges, ({ age, count }) => ({
    label: age,
    count,
    percentage: (count / totalTeamAgeCount) * 100,
  }))

  return (
    <OverviewCard className="mt-5" heading="Player ages">
      <OverviewBreakdownBars className="mt-5" breakdown={sortBy(teamAges, ({ label }) => Number(split(label, '-')[0]))} />
    </OverviewCard>
  )
})
