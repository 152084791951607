import { useUser } from '@coachmate/user'
import { AnalyticsEventName, analyticsService } from '@coachmate/common'

export const useAnalytics = () => {
  const { isSuperuser } = useUser()

  return {
    init: analyticsService.init,
    track: async (event: AnalyticsEventName, additionalProperties: Record<string, any> = {}) => {
      const properties = { ...additionalProperties, isSuperuser }

      return await analyticsService.track(event, properties)
    },
  }
}
