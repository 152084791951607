import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlCreateAccountInput, GqlCreateAccountMutation, GqlCreateAccountMutationVariables } from '@graphql'

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
    }
  }
`

export const createAccount = async (input: GqlCreateAccountInput) => {
  loggerService.debug('[createAccount] Creating account.')

  const { data } = await apolloService.mutate<GqlCreateAccountMutation, GqlCreateAccountMutationVariables>({
    mutation: CREATE_ACCOUNT,
    variables: { input },
  })

  return data?.createAccount as GqlCreateAccountMutation['createAccount']
}
