import { find, map } from 'lodash'
import { format, parseISO } from 'date-fns'
import React, { memo, useState } from 'react'
import { Tag, useTag } from '@coachmate/tag'
import { useEntity } from '@coachmate/entity'
import { routerService } from '@coachmate/router'
import { GqlCoreAdminUserFieldsFragment } from '@graphql'
import { superuserDisableUser, superuserEnableUser, superuserVerifyUser } from '@coachmate/superuser'
import { ManageUserTagsModal, EnrolUserInSportModal, ManageUserAccountsModal, useUser } from '@coachmate/user'
import {
  Td,
  Tr,
  Text,
  Badge,
  Icon,
  DATE_TIME_FORMAT,
  Button,
  Dropdown,
  DropdownContent,
  useModal,
  useCommon,
  useToast,
  utilService,
  useAnalytics,
  sentryService,
} from '@coachmate/common'

type Props = {
  adminUser: GqlCoreAdminUserFieldsFragment
  refetch: () => Promise<void>
}

export const UserListRow = memo(({ adminUser, refetch }: Props) => {
  const { tags } = useTag()
  const { track } = useAnalytics()
  const { entities, entity } = useEntity()
  const { openModal } = useModal()
  const { openToast } = useToast()
  const { isSuperuser } = useUser()
  const { isUiSaving, setUi } = useCommon()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const userId = adminUser.user.id

  const handleError = (error: any) => {
    openToast(<Text>{error.message || 'Something went wrong'}</Text>, 'danger')
    setUi('ready')
  }

  const handleDropdownAsync = async (callback: () => Promise<any>) => {
    try {
      setUi('saving')
      await callback()
      await refetch()
      setIsDropdownOpen(false)
      setUi('ready')
    } catch (error: any) {
      openToast(<Text>{error.message || 'Something went wrong'}</Text>, 'danger')
      setUi('ready')
    }
  }

  const handleEditTagsClick = () => {
    setIsDropdownOpen(false)
    openModal(<ManageUserTagsModal adminUser={adminUser} refetch={refetch} />)
  }

  const handleEnrolInSportClick = () => {
    setIsDropdownOpen(false)
    openModal(<EnrolUserInSportModal adminUser={adminUser} refetch={refetch} />)
  }

  const handleManageAdminEntitiesClick = () => {
    setIsDropdownOpen(false)
    openModal(<ManageUserAccountsModal adminUser={adminUser} refetch={refetch} />)
  }

  const handleVerifyClick = async () => {
    await handleDropdownAsync(async () => {
      try {
        track('admin_verify_user_click', { userId })
        await superuserVerifyUser({ userId })
      } catch (error: any) {
        handleError(error)
        track('admin_verify_user_click_error', { userId, error: error.message })
        sentryService.captureException({ exception: error, extras: { userId } })
      }
    })
  }

  const handleDisableClick = async () => {
    await handleDropdownAsync(async () => {
      try {
        track('admin_disable_user_click', { userId })
        await superuserDisableUser({ userId })
      } catch (error: any) {
        handleError(error)
        track('admin_disable_user_click_error', { userId, error: error.message })
        sentryService.captureException({ exception: error, extras: { userId } })
      }
    })
  }

  const handleEnableClick = async () => {
    await handleDropdownAsync(async () => {
      try {
        track('admin_enable_user_click', { userId })
        await superuserEnableUser({ userId })
      } catch (error: any) {
        handleError(error)
        track('admin_enable_user_click_error', { userId, error: error.message })
        sentryService.captureException({ exception: error, extras: { userId } })
      }
    })
  }

  return (
    <Tr>
      <Td>
        <Button
          variant="info"
          href={routerService.getHref('/user/view/:userId', { userId: adminUser.user.id })}
          state="text"
          isDisabled={isUiSaving}
          isLink
        >
          {utilService.getFullName(adminUser.user)}
          {isSuperuser && adminUser.isSuperuser && ' (Superuser)'}
          {!isSuperuser && !adminUser.accounts.filter((account) => account.entityId == entity?.id).length ? (
            <span className="ml-3 text-xs antialiased text-warning text-opacity-60">(Request Access)</span>
          ) : (
            ''
          )}
        </Button>
        <Text className="text-xs" variant="primary-3">
          {adminUser.user.email}
        </Text>
      </Td>
      {isSuperuser && (
        <Td>
          {map(adminUser.accounts, ({ entityId }, index) => {
            const entity = find(entities, { id: entityId })

            if (!entity) {
              return null
            }

            return (
              <Badge key={index} className="m-1 p-2 w-100 truncate" variant="neutral">
                {entity.name}
                {Boolean(find(adminUser.accounts, { entityId: entity.id })?.isAdmin) && ' (admin)'}
              </Badge>
            )
          })}
        </Td>
      )}
      {isSuperuser && (
        <Td>
          {map(adminUser.tags, (tagId) => {
            const tag = find(tags, { id: tagId })

            return tag ? <Tag key={tag.id} className="mr-1 mb-1 truncate" tag={tag} /> : null
          })}
        </Td>
      )}
      <Td className="text-center">
        {adminUser.user.isVerified ? (
          <Icon className="mr-1" icon="check" variant="success" width={11} height={8} />
        ) : (
          <Icon className="mr-1" icon="close" variant="danger" width={8} height={8} />
        )}
      </Td>
      <Td className="text-center">
        <Text className="text-xs" variant="primary-3">
          {adminUser.user.appVersion}
        </Text>
      </Td>
      <Td className="text-center">
        <Text className="text-xs" variant="primary-3">
          {adminUser.user.created_at ? format(parseISO(adminUser.user.created_at), DATE_TIME_FORMAT.fullDate) : '–'}
        </Text>
      </Td>
      {isSuperuser && (
        <Td className="text-right">
          <Button className="h-8" onClick={() => setIsDropdownOpen(true)} state="text" isDisabled={isUiSaving}>
            <Icon icon="dots-horizontal" variant="primary-3" />
          </Button>
          <Dropdown className="top-14 -right-6" onClickOutside={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
            <DropdownContent onClick={() => handleEditTagsClick()} isDisabled={isUiSaving}>
              <div className="flex items-center">
                <Icon className="mr-2" icon="tag-filled" variant="info" width={16} height={16} />
                <Text>Edit tags</Text>
              </div>
            </DropdownContent>
            {!adminUser.isSuperuser && Boolean(adminUser.accounts.length) && (
              <DropdownContent onClick={() => handleManageAdminEntitiesClick()} isDisabled={isUiSaving}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="whistle-outlined" variant="warning" />
                  <Text>Manage entities</Text>
                </div>
              </DropdownContent>
            )}
            <DropdownContent onClick={() => handleEnrolInSportClick()} isDisabled={isUiSaving}>
              <div className="flex items-center">
                <Icon className="mr-2" icon="add-circle-outlined" variant="success" />
                <Text>Enroll in sport</Text>
              </div>
            </DropdownContent>
            {!adminUser.user.isVerified && (
              <DropdownContent onClick={() => handleVerifyClick()} isDisabled={isUiSaving}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="user-check-outlined" variant="info" />
                  <Text>Verify user</Text>
                </div>
              </DropdownContent>
            )}
            {adminUser.user.status === 'active' && (
              <DropdownContent onClick={() => handleDisableClick()} isDisabled={isUiSaving}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="user-close-outlined" variant="danger" />
                  <Text>Disable user</Text>
                </div>
              </DropdownContent>
            )}
            {adminUser.user.status === 'inactive' && (
              <DropdownContent onClick={() => handleEnableClick()} isDisabled={isUiSaving}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="user-check-outlined" variant="success" />
                  <Text>Enable user</Text>
                </div>
              </DropdownContent>
            )}
          </Dropdown>
        </Td>
      )}
    </Tr>
  )
})
