import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewCard } from '@coachmate/overview'
import { NumberAnimation, Text, utilService } from '@coachmate/common'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSection = memo(({ overview }: Props) => (
  <div className="flex items-center justify-between mt-5">
    <OverviewCard className="mr-5" heading="No. of users">
      <Text className="text-4xl mt-2">
        <NumberAnimation value={overview.userCount} format={utilService.formatInt} duration={0.5} />
      </Text>
    </OverviewCard>
    <OverviewCard className="mr-5" heading="No. of coaches">
      <Text className="text-4xl mt-2">
        <NumberAnimation value={overview.coachCount} format={utilService.formatInt} duration={0.5} />
      </Text>
    </OverviewCard>
    <OverviewCard heading="No. of teams">
      <Text className="text-4xl mt-2">
        <NumberAnimation value={overview.teamCount} format={utilService.formatInt} duration={0.5} />
      </Text>
    </OverviewCard>
  </div>
))
