import React, { memo, useEffect, useRef, useState } from 'react'
import { animate, AnimationPlaybackControls } from 'framer-motion'
import { TRANSITION_DURATION } from '@coachmate/common'

type Props = {
  value: number
  format?: (value: number) => string
  duration?: number
  isAnimatedInitially?: boolean
}

export const NumberAnimation = memo(({ value = 0, format, duration = TRANSITION_DURATION.md, isAnimatedInitially = true }: Props) => {
  const divRef = useRef<HTMLDivElement>(null)
  const oldValueRef = useRef<number>(0)
  const [isInitialised, setIsInitialised] = useState(isAnimatedInitially)

  useEffect(() => {
    let controls: AnimationPlaybackControls | undefined

    if (isAnimatedInitially || isInitialised) {
      controls = animate(oldValueRef.current, value, {
        duration,
        onUpdate(value: number) {
          if (divRef.current) {
            divRef.current.textContent = format ? format(value) : value.toFixed(0).toString()
          }
        },
        onComplete() {
          oldValueRef.current = value
        },
      })
    } else {
      if (divRef.current) {
        divRef.current.textContent = format ? format(value) : value.toFixed(0).toString()
      }
    }

    setIsInitialised(true)

    return () => controls?.stop()
  }, [value])

  return <span ref={divRef} />
})
