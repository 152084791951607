import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { ACCOUNT_CORE_FIELDS_FRAGMENT } from '@coachmate/account/graphql/account-fragment.graphql'
import { GqlSuperuserUpdateAccountInput, GqlSuperuserUpdateAccountMutation, GqlSuperuserUpdateAccountMutationVariables } from '@graphql'

const SUPERUSER_UPDATE_ACCOUNT = gql`
  ${ACCOUNT_CORE_FIELDS_FRAGMENT}

  mutation SuperuserUpdateAccount($input: SuperuserUpdateAccountInput!) {
    superuserUpdateAccount(input: $input) {
      ...CoreAccountFields
    }
  }
`

export const superuserUpdateAccount = async (input: GqlSuperuserUpdateAccountInput) => {
  loggerService.debug('[superuserUpdateAccount] Verifying user.')

  const { data } = await apolloService.mutate<GqlSuperuserUpdateAccountMutation, GqlSuperuserUpdateAccountMutationVariables>({
    mutation: SUPERUSER_UPDATE_ACCOUNT,
    variables: { input },
  })

  return data?.superuserUpdateAccount
}
