import classNames from 'classnames'
import React, { memo, SVGProps } from 'react'
import { componentService, TextVariant } from '@coachmate/common'

import AcademicCapOutlinedIconSvg from '@assets/svg/icon-academic-cap-outlined.svg'
import AddCircleOutlinedIconSvg from '@assets/svg/icon-add-circle-outlined.svg'
import ArrowLeftIconSvg from '@assets/svg/icon-arrow-left.svg'
import BarGraphFilledIconSvg from '@assets/svg/icon-bar-graph-filled.svg'
import BarGraphHorizontalOutlinedIconSvg from '@assets/svg/icon-bar-graph-horizontal-outlined.svg'
import BookmarkOutlinedIconSvg from '@assets/svg/icon-bookmark-outlined.svg'
import CalendarDeleteIconSvg from '@assets/svg/icon-calendar-delete.svg'
import CalendarEditIconSvg from '@assets/svg/icon-calendar-edit.svg'
import CalendarIconSvg from '@assets/svg/icon-calendar.svg'
import CheckCircleFilledIconSvg from '@assets/svg/icon-check-circle-filled.svg'
import CheckCircleOutlinedIconSvg from '@assets/svg/icon-check-circle-outlined.svg'
import CheckIconSvg from '@assets/svg/icon-check.svg'
import ChevronDownIconSvg from '@assets/svg/icon-chevron-down.svg'
import ChevronLeftIconSvg from '@assets/svg/icon-chevron-left.svg'
import ChevronRightIconSvg from '@assets/svg/icon-chevron-right.svg'
import CloseCircleFilledIconSvg from '@assets/svg/icon-close-circle-filled.svg'
import CloseCircleOutlinedIconSvg from '@assets/svg/icon-close-circle-outlined.svg'
import CloseIconSvg from '@assets/svg/icon-close.svg'
import CogOutlinedIconSvg from '@assets/svg/icon-cog-outlined.svg'
import DocumentOutlinedIconSvg from '@assets/svg/icon-document-outlined.svg'
import DotsHorizontalIconSvg from '@assets/svg/icon-dots-horizontal.svg'
import DuplicateOutlinedIconSvg from '@assets/svg/icon-duplicate-outlined.svg'
import HeartOutlinedIconSvg from '@assets/svg/icon-heart-outlined.svg'
import HideIconSvg from '@assets/svg/icon-hide.svg'
import HomeOutlinedIconSvg from '@assets/svg/icon-home-outlined.svg'
import LightBulbOutlinedIconSvg from '@assets/svg/icon-light-bulb-outlined.svg'
import LocationMarkerFilledIconSvg from '@assets/svg/icon-location-marker-filled.svg'
import LockClosedOutlinedIconSvg from '@assets/svg/icon-lock-closed-outlined.svg'
import LogoutOutlinedIconSvg from '@assets/svg/icon-logout-outlined.svg'
import MailOpenFilledIconSvg from '@assets/svg/icon-mail-open-filled.svg'
import MailOpenOutlinedIconSvg from '@assets/svg/icon-mail-open-outlined.svg'
import MenuIconSvg from '@assets/svg/icon-menu.svg'
import OpenBookOutlinedIconSvg from '@assets/svg/icon-open-book-outlined.svg'
import PauseFilledIconSvg from '@assets/svg/icon-pause-filled.svg'
import PauseOutlinedIconSvg from '@assets/svg/icon-pause-outlined.svg'
import PencilFilledIconSvg from '@assets/svg/icon-pencil-filled.svg'
import PlayCircleOutlinedIconSvg from '@assets/svg/icon-play-circle-outlined.svg'
import PlayFilledIconSvg from '@assets/svg/icon-play-filled.svg'
import ProgressOutlinedIconSvg from '@assets/svg/icon-progress-outlined.svg'
import RadioSelectedIconSvg from '@assets/svg/icon-radio-selected.svg'
import RadioUnselectedIconSvg from '@assets/svg/icon-radio-unselected.svg'
import RunningFilledIconSvg from '@assets/svg/icon-running-filled.svg'
import SearchOutlinedIconSvg from '@assets/svg/icon-search-outlined.svg'
import ShowIconSvg from '@assets/svg/icon-show.svg'
import StarFilledIconSvg from '@assets/svg/icon-star-filled.svg'
import StarOutlinedIconSvg from '@assets/svg/icon-star-outlined.svg'
import TagFilledIconSvg from '@assets/svg/icon-tag-filled.svg'
import ThumbsDownFilledIconSvg from '@assets/svg/icon-thumbs-down-filled.svg'
import ThumbsDownOutlinedIconSvg from '@assets/svg/icon-thumbs-down-outlined.svg'
import ThumbsUpFilledIconSvg from '@assets/svg/icon-thumbs-up-filled.svg'
import ThumbsUpOutlinedIconSvg from '@assets/svg/icon-thumbs-up-outlined.svg'
import TickCircleFilledIconSvg from '@assets/svg/icon-tick-circle-filled.svg'
import TimerIconSvg from '@assets/svg/icon-timer.svg'
import TrashOutlinedIconSvg from '@assets/svg/icon-trash-outlined.svg'
import UserCheckOutlinedIconSvg from '@assets/svg/icon-user-check-outlined.svg'
import UserCloseOutlinedIconSvg from '@assets/svg/icon-user-close-outlined.svg'
import UserGroupOutlinedIconSvg from '@assets/svg/icon-user-group-outlined.svg'
import UserOutlined2IconSvg from '@assets/svg/icon-user-outlined-2.svg'
import UserOutlinedIconSvg from '@assets/svg/icon-user-outlined.svg'
import WhistleOutlinedIconSvg from '@assets/svg/icon-whistle-outlined.svg'

export type IconType =
  | 'academic-cap-outlined'
  | 'add-circle-outlined'
  | 'arrow-left'
  | 'bar-graph-filled'
  | 'bar-graph-horizontal-outlined'
  | 'bookmark-outlined'
  | 'calendar-delete'
  | 'calendar-edit'
  | 'calendar'
  | 'check-circle-filled'
  | 'check-circle-outlined'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'close-circle-filled'
  | 'close-circle-outlined'
  | 'close'
  | 'cog-outlined'
  | 'document-outlined'
  | 'dots-horizontal'
  | 'duplicate-outlined'
  | 'heart-outlined'
  | 'hide'
  | 'home-outlined'
  | 'light-bulb-outlined'
  | 'location-marker-filled'
  | 'lock-closed-outlined'
  | 'logout-outlined'
  | 'mail-open-filled'
  | 'mail-open-outlined'
  | 'menu'
  | 'open-book-outlined'
  | 'pause-filled'
  | 'pause-outlined'
  | 'pencil-filled'
  | 'play-circle-outlined'
  | 'play-filled'
  | 'progress-outlined'
  | 'radio-selected'
  | 'radio-unselected'
  | 'running-filled'
  | 'search-outlined'
  | 'show'
  | 'star-filled'
  | 'star-outlined'
  | 'tag-filled'
  | 'thumbs-down-filled'
  | 'thumbs-down-outlined'
  | 'thumbs-side-filled'
  | 'thumbs-side-outlined'
  | 'thumbs-up-filled'
  | 'thumbs-up-outlined'
  | 'tick-circle-filled'
  | 'timer'
  | 'trash-outlined'
  | 'user-check-outlined'
  | 'user-close-outlined'
  | 'user-group-outlined'
  | 'user-outlined-2'
  | 'user-outlined'
  | 'whistle-outlined'

type Props = {
  icon: IconType
  className?: string
  height?: number
  width?: number
  variant?: TextVariant
}

export const Icon = memo(({ className, icon, height, width, variant = 'none' }: Props) => {
  const baseClasses = classNames(className, 'inline-block', componentService.getTextClasses(variant))
  const filledIconClasses = classNames(baseClasses, 'fill-current')
  const outlinedIconClasses = classNames(baseClasses, 'stroke-current')

  const getSvgAttrs = (defaultWidth: number, defaultHeight: number) => {
    const attrs: SVGProps<SVGSVGElement> = {
      width: width || defaultWidth,
      height: height || defaultHeight,
    }

    if (attrs.width !== defaultWidth || attrs.height !== defaultHeight) {
      attrs.viewBox = `0 0 ${defaultWidth} ${defaultHeight}`
    }

    return attrs
  }

  switch (icon) {
    case 'academic-cap-outlined':
      return <AcademicCapOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 19)} />
    case 'add-circle-outlined':
      return <AddCircleOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'arrow-left':
      return <ArrowLeftIconSvg className={outlinedIconClasses} {...getSvgAttrs(21, 10)} />
    case 'bar-graph-filled':
      return <BarGraphFilledIconSvg className={filledIconClasses} {...getSvgAttrs(18, 16)} />
    case 'bar-graph-horizontal-outlined':
      return <BarGraphHorizontalOutlinedIconSvg className={filledIconClasses} {...getSvgAttrs(18, 18)} />
    case 'bookmark-outlined':
      return <BookmarkOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(16, 21)} />
    case 'calendar-delete':
      return <CalendarDeleteIconSvg className={filledIconClasses} {...getSvgAttrs(18, 20)} />
    case 'calendar-edit':
      return <CalendarEditIconSvg className={filledIconClasses} {...getSvgAttrs(18, 20)} />
    case 'calendar':
      return <CalendarIconSvg className={filledIconClasses} {...getSvgAttrs(18, 20)} />
    case 'check-circle-filled':
      return <CheckCircleFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'check-circle-outlined':
      return <CheckCircleOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'check':
      return <CheckIconSvg className={outlinedIconClasses} {...getSvgAttrs(16, 13)} />
    case 'chevron-down':
      return <ChevronDownIconSvg className={outlinedIconClasses} {...getSvgAttrs(16, 9)} />
    case 'chevron-left':
      return <ChevronLeftIconSvg className={outlinedIconClasses} {...getSvgAttrs(9, 16)} />
    case 'chevron-right':
      return <ChevronRightIconSvg className={outlinedIconClasses} {...getSvgAttrs(9, 16)} />
    case 'close-circle-filled':
      return <CloseCircleFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'close-circle-outlined':
      return <CloseCircleOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'close':
      return <CloseIconSvg className={outlinedIconClasses} {...getSvgAttrs(14, 14)} />
    case 'cog-outlined':
      return <CogOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'document-outlined':
      return <DocumentOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(16, 20)} />
    case 'dots-horizontal':
      return <DotsHorizontalIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 4)} />
    case 'duplicate-outlined':
      return <DuplicateOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 18)} />
    case 'heart-outlined':
      return <HeartOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 22)} />
    case 'hide':
      return <HideIconSvg className={filledIconClasses} {...getSvgAttrs(20, 19)} />
    case 'home-outlined':
      return <HomeOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 22)} />
    case 'light-bulb-outlined':
      return <LightBulbOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'location-marker-filled':
      return <LocationMarkerFilledIconSvg className={filledIconClasses} {...getSvgAttrs(14, 19)} />
    case 'lock-closed-outlined':
      return <LockClosedOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 21)} />
    case 'logout-outlined':
      return <LogoutOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 18)} />
    case 'mail-open-filled':
      return <MailOpenFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'mail-open-outlined':
      return <MailOpenOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'menu':
      return <MenuIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 15)} />
    case 'open-book-outlined':
      return <OpenBookOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(21, 17)} />
    case 'pause-filled':
      return <PauseFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'pause-outlined':
      return <PauseOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'pencil-filled':
      return <PencilFilledIconSvg className={filledIconClasses} {...getSvgAttrs(18, 18)} />
    case 'play-circle-outlined':
      return <PlayCircleOutlinedIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'play-filled':
      return <PlayFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'progress-outlined':
      return <ProgressOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 18)} />
    case 'radio-selected':
      return <RadioSelectedIconSvg className={filledIconClasses} {...getSvgAttrs(14, 14)} />
    case 'radio-unselected':
      return <RadioUnselectedIconSvg className={filledIconClasses} {...getSvgAttrs(14, 14)} />
    case 'running-filled':
      return <RunningFilledIconSvg className={filledIconClasses} {...getSvgAttrs(14, 14)} />
    case 'search-outlined':
      return <SearchOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(20, 20)} />
    case 'show':
      return <ShowIconSvg className={filledIconClasses} {...getSvgAttrs(20, 19)} />
    case 'star-filled':
      return <StarFilledIconSvg className={filledIconClasses} {...getSvgAttrs(22, 21)} />
    case 'star-outlined':
      return <StarOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(22, 21)} />
    case 'tag-filled':
      return <TagFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'thumbs-down-filled':
      return <ThumbsDownFilledIconSvg className={filledIconClasses} {...getSvgAttrs(24, 24)} />
    case 'thumbs-down-outlined':
      return <ThumbsDownOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(24, 24)} />
    case 'thumbs-up-filled':
      return <ThumbsUpFilledIconSvg className={filledIconClasses} {...getSvgAttrs(24, 24)} />
    case 'thumbs-up-outlined':
      return <ThumbsUpOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(24, 24)} />
    case 'thumbs-side-filled':
      return <ThumbsUpFilledIconSvg className={classNames(filledIconClasses, 'transform -rotate-90')} {...getSvgAttrs(24, 24)} />
    case 'thumbs-side-outlined':
      return <ThumbsUpOutlinedIconSvg className={classNames(outlinedIconClasses, 'transform -rotate-90')} {...getSvgAttrs(24, 24)} />
    case 'tick-circle-filled':
      return <TickCircleFilledIconSvg className={filledIconClasses} {...getSvgAttrs(20, 20)} />
    case 'timer':
      return <TimerIconSvg className={filledIconClasses} {...getSvgAttrs(17, 19)} />
    case 'trash-outlined':
      return <TrashOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 20)} />
    case 'user-check-outlined':
      return <UserCheckOutlinedIconSvg className={filledIconClasses} {...getSvgAttrs(20, 15)} />
    case 'user-close-outlined':
      return <UserCloseOutlinedIconSvg className={filledIconClasses} {...getSvgAttrs(20, 15)} />
    case 'user-group-outlined':
      return <UserGroupOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(22, 18)} />
    case 'user-outlined-2':
      return <UserOutlined2IconSvg className={filledIconClasses} {...getSvgAttrs(16, 19)} />
    case 'user-outlined':
      return <UserOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(16, 20)} />
    case 'whistle-outlined':
      return <WhistleOutlinedIconSvg className={outlinedIconClasses} {...getSvgAttrs(18, 22)} />
    default:
      return <></>
  }
})
