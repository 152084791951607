import { map } from 'lodash'
import React, { FormEvent, memo, useEffect, useState } from 'react'
import { useEntity } from '@coachmate/entity'
import { AppErrorPage } from '@coachmate/app'
import { adminUserList } from '@coachmate/admin'
import { GqlCorePaginationFieldsFragment, GqlAdminUserListFilterInput, GqlCoreAdminUserFieldsFragment } from '@graphql'
import {
  BASE_FILTER_FORM_DATA,
  UserListActions,
  UserListFilterFormData,
  UserListRowRequestAccess,
  UserListRowSkeleton,
  useUser,
} from '@coachmate/user'
import {
  ListPagination,
  Page,
  Section,
  sentryService,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useAnalytics,
  useCommon,
  useRunQuery,
} from '@coachmate/common'

type FetchUsersParam = {
  page: number
  filterFormData: UserListFilterFormData
}

export const UserListRequestAccessPage = memo(() => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { entities } = useEntity()
  const { isSuperuser } = useUser()
  const [page, setPage] = useState(1)
  const { setUi, isUiError } = useCommon()
  const [isLoading, setIsLoading] = useState(true)
  const [requestAccessUsers, setRequestAccessUsers] = useState<GqlCoreAdminUserFieldsFragment[]>([])
  const [pagination, setPagination] = useState<GqlCorePaginationFieldsFragment>()
  const [filterFormData, setFilterFormData] = useState<UserListFilterFormData>({ ...BASE_FILTER_FORM_DATA, isRequestAccess: true })

  const fetchUsers = async ({ page, filterFormData }: FetchUsersParam) => {
    await runQuery(async () => {
      const { isVerified, ...restFilterFormData } = filterFormData
      const filterToSubmit: GqlAdminUserListFilterInput = { ...restFilterFormData }

      if (isVerified !== 'both') {
        filterToSubmit.isVerified = isVerified === 'yes' ? true : false
      }

      const { items, pagination } = await adminUserList({
        pagination: { page, pageSize: 20 },
        sort: { sortBy: 'created_at', direction: 'desc' },
        filter: filterToSubmit,
      })

      setRequestAccessUsers(items)
      setPagination(pagination)
      setUi('ready')
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (isSuperuser) {
          await fetchUsers({ page, filterFormData })
        } else {
          const newFilterFormData = { ...filterFormData, entityId: entities[0].id }
          setFilterFormData(newFilterFormData)
          await fetchUsers({ page, filterFormData: newFilterFormData })
        }
        setIsLoading(false)
      } catch (error: any) {
        setUi('error')
        track('admin_user_list_view_error', { error: error.message })
        sentryService.captureException({ exception: error })
      }
    })()
  }, [])

  if (isUiError) {
    return <AppErrorPage />
  }

  const handlePageChange = (page: number) => {
    try {
      track('admin_user_list_page_change', { page })
      setUi('saving')
      setPage(page)
      fetchUsers({ page, filterFormData })
    } catch (error: any) {
      setUi('error')
      track('admin_user_list_page_change_error', { error: error.message, page })
      sentryService.captureException({ exception: error, extras: { page } })
    }
  }

  const handleFilterSubmit = (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()

      track('admin_user_list_filter_submit', { ...filterFormData })
      setUi('saving')
      setPage(1)
      fetchUsers({ page: 1, filterFormData })
    } catch (error: any) {
      setUi('error')
      track('admin_user_list_filter_submit_error', { error: error.message, ...filterFormData })
      sentryService.captureException({ exception: error, extras: { ...filterFormData } })
    }
  }

  const handleFilterReset = () => {
    try {
      track('admin_user_list_filter_reset')
      setUi('saving')
      setPage(1)

      const newFilterFormData = { ...filterFormData, entityId: entities[0].id, isRequestAccess: true }
      setFilterFormData(newFilterFormData)
      fetchUsers({ page: 1, filterFormData: newFilterFormData })
    } catch (error: any) {
      setUi('error')
      track('admin_user_list_filter_reset_error', { error: error.message })
      sentryService.captureException({ exception: error })
    }
  }

  const renderLoading = () => (
    <Tbody>
      {map(new Array(20), (_, index) => (
        <UserListRowSkeleton key={index} />
      ))}
    </Tbody>
  )

  const renderContent = () => {
    if (!requestAccessUsers.length) {
      return (
        <Tbody>
          <Tr>
            <Td colSpan={5}>
              <Text className="text-sm text-center" variant="primary-3">
                No results found
              </Text>
            </Td>
          </Tr>
        </Tbody>
      )
    }

    return (
      <Tbody>
        {map(requestAccessUsers, (adminUser, index) => (
          <UserListRowRequestAccess
            key={`access-request-${adminUser.user.id}-${index}`}
            adminUser={adminUser}
            refetch={async () => fetchUsers({ page, filterFormData })}
          />
        ))}
        {pagination && Boolean(pagination.pageCount > 1) && (
          <ListPagination page={page} pagination={pagination} onPageChange={handlePageChange} colSpan={6} />
        )}
      </Tbody>
    )
  }

  return (
    <Page>
      <Section className="pt-16 pb-5">
        <Text className="text-4xl font-semibold mb-5">Early Access Request</Text>
        <Text className="text-sm font-light">
          These are users that have requested early access to your sport on CoachMate before it has launched.
        </Text>
        <div className="border border-ui-800 rounded-lg p-5 mt-8">
          <UserListActions
            filterFormData={filterFormData}
            setFilterFormData={setFilterFormData}
            onFilterReset={handleFilterReset}
            onFilterSubmit={handleFilterSubmit}
            isRequestAccess={true}
          />

          <div className="bg-ui-600 h-px w-full mt-8" />

          <TableContainer className="mt-8">
            <Table>
              <Thead>
                <Th className="w-1/4">Name</Th>
                {isSuperuser && <Th>Request Tag</Th>}
                {isSuperuser && <Th className="w-1" />}
              </Thead>
              {isLoading && renderLoading()}
              {!isLoading && renderContent()}
            </Table>
          </TableContainer>
        </div>
      </Section>
    </Page>
  )
})
