import React, { memo } from 'react'
import { Button, Page, Section, Text } from '@coachmate/common'

export const MaintenancePage = memo(() => (
  <Page>
    <Section>
      <Text className="text-center text-2xl font-semibold">Under Maintenance</Text>

      <Text className="text-center mt-8">CoachMate is currently under maintenance.</Text>

      <Text className="text-center mt-8">
        Come back later to see if we&apos;re back up and running, or email{' '}
        <Button target="_blank" href="mailto:support@coachmate.sport" rel="noreferrer" state="text" isLink isExternal>
          support@coachmate.sport
        </Button>{' '}
        for more information.
      </Text>
    </Section>
  </Page>
))
