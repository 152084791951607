import classNames from 'classnames'
import React, { ReactNode, HTMLProps, memo } from 'react'
import { Tr } from './tr.component'

type Props = HTMLProps<HTMLTableSectionElement> & {
  children: ReactNode
  className?: string
}

export const Thead = memo(({ className, children, ...rest }: Props) => {
  const classes = classNames(className, 'divide-y', 'divide-ui-800')

  return (
    <thead {...rest} className={classes}>
      <Tr variant="thead">{children}</Tr>
    </thead>
  )
})
