import { find, forEach } from 'lodash'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { loggerService } from './logger.service'
import { ERROR_MESSAGE } from '../constants/error-message.constants'

const SENTRY_EXCLUDED_ERRORS = [ERROR_MESSAGE.accountAlreadyExists, ERROR_MESSAGE.authentication, ERROR_MESSAGE.invalidPassword] as const

type CaptureExceptionParam = {
  exception: any
  tags?: Record<string, string | number | undefined>
  extras?: Record<string, string | number | boolean | undefined>
  transaction?: string
  level?: Sentry.SeverityLevel
  userId?: string
}

class SentryService {
  public _isInitialised = false

  init = async () => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: `coachmate-admin@${process.env.npm_package_version}`,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
    })

    this._isInitialised = true
  }

  setUser = async (userId: string) => {
    if (!this._isInitialised) {
      return
    }

    Sentry.setUser({ id: userId })
  }

  captureException = ({ exception, tags, extras, transaction, level = 'error' }: CaptureExceptionParam) => {
    loggerService.error(exception)

    if (!this._isInitialised) {
      return
    }

    if (find(SENTRY_EXCLUDED_ERRORS, (errorMessage) => errorMessage === exception || errorMessage === exception.message)) {
      return
    }

    Sentry.withScope((scope) => {
      scope.setExtra('pathname', location.pathname)

      if (level) {
        // ! There is a bug in the Sentry TypeScript definitions, so casting this to any as a workaround.
        scope.setLevel(level as any)
      }

      forEach(tags, (value, key) => {
        if (value) {
          scope.setTag(key, value)
        }
      })

      forEach(extras, (value, key) => {
        if (value) {
          scope.setExtra(key, value)
        }
      })

      if (transaction) {
        scope.setTransactionName(transaction)
      }

      Sentry.captureException(exception)
    })
  }
}

export const sentryService = new SentryService()
