import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlUpdateUserTagsInput, GqlUpdateUserTagsMutation, GqlUpdateUserTagsMutationVariables } from '@graphql'

const UPDATE_USER_TAGS = gql`
  mutation UpdateUserTags($input: UpdateUserTagsInput!) {
    updateUserTags(input: $input)
  }
`

export const updateUserTags = async (input: GqlUpdateUserTagsInput) => {
  loggerService.debug('[updateUserTags] Updating user tags.')

  const { data } = await apolloService.mutate<GqlUpdateUserTagsMutation, GqlUpdateUserTagsMutationVariables>({
    mutation: UPDATE_USER_TAGS,
    variables: { input },
  })

  return data?.updateUserTags
}
