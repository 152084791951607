import { trim } from 'lodash'

type GetFullNameParam = {
  firstName: string
  lastName: string
}

type GetInitialsParam = GetFullNameParam

class UtilService {
  generateUid = () => {
    let date = new Date().getTime()

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      const random = (date + Math.random() * 16) % 16 | 0

      date = Math.floor(date / 16)

      return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16)
    })
  }

  toStartCase = (value: string) => {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
  }

  formatInt = (value: string | number, maximumFractionDigits = 0) => {
    return Intl.NumberFormat('en-GB', { maximumFractionDigits }).format(Number(value) || 0)
  }

  async asyncForEach<T, R = unknown>(collection: T[], callback: (item: T, index: number, collection: T[]) => Promise<R>) {
    for (let index = 0; index < collection.length; index += 1) {
      await callback(collection[index], index, collection)
    }
  }

  getUrlSearchParamByKey = (key: string) => {
    return new URLSearchParams(window.location.search).get(key)
  }

  getFullName({ firstName, lastName }: GetFullNameParam) {
    return trim(`${trim(firstName)} ${trim(lastName)}`)
  }

  getInitials = (user: GetInitialsParam) => {
    const fullName = this.getFullName(user)
    const names = fullName.split(' ')

    if (!names.length || !names[0]) {
      return '?'
    }

    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }

    return initials
  }
}

export const utilService = new UtilService()
