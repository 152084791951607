import { gql } from '@apollo/client'
import { GqlAdminContextQuery } from '@graphql'
import { apolloService, loggerService } from '@coachmate/common'
import { TAG_CORE_FIELDS_FRAGMENT } from '@coachmate/tag/graphql/tag-fragment.graphql'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { ENTITY_CORE_FIELDS_FRAGMENT } from '@coachmate/entity/graphql/entity-fragment.graphql'

export const ADMIN_CONTEXT = gql`
  ${TAG_CORE_FIELDS_FRAGMENT}
  ${USER_CORE_FIELDS_FRAGMENT}
  ${ENTITY_CORE_FIELDS_FRAGMENT}

  query AdminContext {
    adminContext {
      user {
        ...CoreUserFields
      }
      entities {
        ...CoreEntityFields
      }
      tags {
        ...CoreTagFields
      }
      isSuperuser
      entityAccessRequestCount
    }
  }
`

export const adminContext = async () => {
  loggerService.debug('[adminContext] Get admin context.')

  const { data } = await apolloService.query<GqlAdminContextQuery>({
    query: ADMIN_CONTEXT,
    fetchPolicy: 'network-only',
  })

  return data.adminContext
}
