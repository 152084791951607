import { gql } from '@apollo/client'

export const USER_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreUserFields on User {
    id
    firebaseUid
    firstName
    lastName
    email
    timezone
    metadata
    appVersion
    isVerified
    status
    created_at
    updated_at
  }
`
