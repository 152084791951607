import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlApproveEntityAccessInput, GqlApproveAccountEntityAccessMutation, GqlApproveAccountEntityAccessMutationVariables } from '@graphql'

const APPROVE_ENTITY_ACCOUNT = gql`
  mutation ApproveAccountEntityAccess($input: ApproveEntityAccessInput!) {
    approveAccountEntityAccess(input: $input) {
      id
    }
  }
`

export const approveEntityAccount = async (input: GqlApproveEntityAccessInput) => {
  loggerService.debug('[approveAccount] Approve account.')

  const { data } = await apolloService.mutate<GqlApproveAccountEntityAccessMutation, GqlApproveAccountEntityAccessMutationVariables>({
    mutation: APPROVE_ENTITY_ACCOUNT,
    variables: { input },
  })

  return data?.approveAccountEntityAccess as GqlApproveAccountEntityAccessMutation['approveAccountEntityAccess']
}
