import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { TagListPage } from '@coachmate/tag'
import { Redirect, Route } from '@coachmate/router'

export const TagLayout = memo(() => (
  <Switch>
    <Route path="/tag/list" exact>
      <TagListPage />
    </Route>
    <Redirect from="/tag" to="/tag/list" />
  </Switch>
))
