import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { clearReactiveUser } from '@coachmate/user'
import { clearReactiveAccount } from '@coachmate/account'
import { localStorageService, clearReactiveCommon, utilService } from '@coachmate/common'

const httpLink = new HttpLink({
  uri: `${process.env.API_DOMAIN}/graphql`,
  fetch,
})

const transactionLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    authorization: localStorageService.getItem('firebase-token') || '',
    'cm-transaction-id': utilService.generateUid(),
  },
}))

export const apolloService = new ApolloClient({
  link: transactionLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export const clearApolloCache = async () => {
  await apolloService.clearStore()
  clearReactiveAccount()
  clearReactiveCommon()
  clearReactiveUser()
}
