import { makeVar, useReactiveVar } from '@apollo/client'
import { GqlCoreAccountFieldsFragment } from '@graphql'

export const accountVar = makeVar<GqlCoreAccountFieldsFragment | null>(null)

export const useAccount = () => {
  const account = useReactiveVar(accountVar)

  return {
    account,
    setAccount: accountVar,
  }
}

export const clearReactiveAccount = () => {
  accountVar(null)
}
