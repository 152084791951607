import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { ReactNode, useMemo } from 'react'
import { TRANSITION_DURATION } from '@coachmate/common'

type Props = {
  className?: string
  children?: ReactNode
  total: number
  completed: number
  size?: 'sm' | 'md'
  isZeroVisible?: boolean
  isLoading?: boolean
}

export const ProgressBar = ({ className, children, total, completed, size = 'md', isZeroVisible, isLoading }: Props) => {
  const width = useMemo(() => (completed / total) * 100, [total, completed])
  const classes = classNames(className, 'relative bg-ui-900 rounded-full', {
    'h-1': size === 'sm',
    'h-2': size === 'md',
  })

  return (
    <div className={classes}>
      <motion.div
        className={classNames('bg-primary-500 rounded-full h-full transition duration-500 ease-in-out', { 'animate-pulse': isLoading })}
        initial={{ width: 0 }}
        animate={{ width: width ? `${width}%` : isZeroVisible ? '8px' : '0px' }}
        transition={{ duration: TRANSITION_DURATION.lg, delay: TRANSITION_DURATION.sm }}
      />
      {children}
    </div>
  )
}
