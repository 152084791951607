import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { componentService } from '@coachmate/common'

export type TextVariant =
  | 'primary'
  | 'primary-1'
  | 'primary-2'
  | 'primary-3'
  | 'primary-4'
  | 'secondary-1'
  | 'secondary-2'
  | 'secondary-3'
  | 'secondary-4'
  | 'info'
  | 'danger'
  | 'success'
  | 'warning'
  | 'none'

type Props = {
  children: ReactNode
  className?: string
  variant?: TextVariant
  onClick?: () => void
  isInline?: boolean
}

export const Text = memo((props: Props) => {
  const { children, className, variant = 'primary-1', onClick, isInline } = props
  const classes = classNames(className, 'antialiased', componentService.getTextClasses(variant))

  return isInline ? (
    <span className={classes} onClick={onClick}>
      {children}
    </span>
  ) : (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  )
})
