import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionUserGender = memo(({ overview }: Props) => {
  const userGenders = [
    { label: 'Female', count: 0, percentage: 0 },
    { label: 'Male', count: 0, percentage: 0 },
    { label: 'Prefer not to say', count: 0, percentage: 0 },
    { label: 'Other', count: 0, percentage: 0 },
  ]

  let totalUserGenderCount = 0
  forEach(overview.userGenders, ({ count }) => {
    totalUserGenderCount += count
  })

  forEach(overview.userGenders, ({ gender, count }) => {
    if (gender === 'Female') {
      userGenders[0].count = count
      userGenders[0].percentage = (count / totalUserGenderCount) * 100
    } else if (gender === 'Male') {
      userGenders[1].count = count
      userGenders[1].percentage = (count / totalUserGenderCount) * 100
    } else if (gender === 'Prefer not to say') {
      userGenders[2].count = count
      userGenders[2].percentage = (count / totalUserGenderCount) * 100
    } else {
      userGenders[3].count += count
    }
  })

  userGenders[3].percentage = (userGenders[3].count / totalUserGenderCount) * 100

  return (
    <OverviewCard className="mt-5" heading="User gender">
      <OverviewBreakdownBars className="mt-5" breakdown={userGenders} />
    </OverviewCard>
  )
})
