import classNames from 'classnames'
import React, { ChangeEvent, HTMLProps, forwardRef } from 'react'

type Props = Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'required' | 'disabled'> & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  id?: string
  className?: string
  testid?: string
  isChecked?: boolean
  isDisabled?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(({ id, className, onChange, testid, isChecked, isDisabled }: Props, ref) => {
  const classes = classNames(className, 'shrink-0 border transition duration-150 ease-in-out rounded-md cursor-pointer outline-none h-5 w-5', {
    'pointer-events-none opacity-50': isDisabled,
  })

  return (
    <input ref={ref} id={id} className={classes} type="checkbox" onChange={onChange} checked={isChecked} disabled={isDisabled} data-testid={testid} />
  )
})
