import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { TagLayout } from '@coachmate/tag'
import { UserLayout } from '@coachmate/user'
import { FourOhFourPage } from '@coachmate/common'
import { Redirect, Route } from '@coachmate/router'
import { OverviewLayout } from '@coachmate/overview'
import { AppHeader, AppSideNav } from '@coachmate/app'

export const AppLayout = memo(() => (
  <>
    <AppHeader />
    <div className="flex">
      <AppSideNav />
      <Switch>
        <Route path="/overview">
          <OverviewLayout />
        </Route>
        <Route path="/user">
          <UserLayout />
        </Route>
        <Route path="/tag">
          <TagLayout />
        </Route>
        <Redirect from="/" to="/overview" exact />
        <Route>
          <FourOhFourPage />
        </Route>
      </Switch>
    </div>
  </>
))
