import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

export const Section = ({ children, className }: Props) => {
  const classes = classNames(className, 'relative px-8 w-full')

  return (
    <div className={classes}>
      <div className="max-w-5xl">{children}</div>
    </div>
  )
}
