import { gql } from '@apollo/client'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { TEAM_CORE_FIELDS_FRAGMENT } from '@coachmate/team/graphql/team-fragment.graphql'
import { ACCOUNT_CORE_FIELDS_FRAGMENT } from '@coachmate/account/graphql/account-fragment.graphql'

export const ADMIN_USER_CORE_FIELDS_FRAGMENT = gql`
  ${USER_CORE_FIELDS_FRAGMENT}
  ${TEAM_CORE_FIELDS_FRAGMENT}
  ${ACCOUNT_CORE_FIELDS_FRAGMENT}

  fragment CoreAdminUserFields on AdminUser {
    user {
      ...CoreUserFields
    }
    accounts {
      ...CoreAccountFields
    }
    teams {
      ...CoreTeamFields
    }
    devices
    tags
    isSuperuser
  }
`
