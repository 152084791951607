import { gql } from '@apollo/client'
import { GqlUserByTokenQuery } from '@graphql'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'

export const USER_BY_TOKEN = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  query UserByToken {
    userByToken {
      ...CoreUserFields
    }
  }
`

export const userByToken = async () => {
  loggerService.debug('[userByToken] Get user by token.')

  const { data } = await apolloService.query<GqlUserByTokenQuery>({
    query: USER_BY_TOKEN,
    fetchPolicy: 'network-only',
  })

  return data.userByToken
}
