import { includes } from 'lodash'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import React, { AnchorHTMLAttributes, HTMLProps, memo, MouseEvent as ReactMouseEvent } from 'react'
import { TextVariant, Text, Spinner } from '@coachmate/common'

export type ButtonVariant = 'primary' | 'neutral' | 'danger' | TextVariant
export type ButtonState = 'filled' | 'outline' | 'ghost' | 'text' | 'block' | 'raw'
export type ButtonShape = 'rect' | 'square'
type AnchorClickEvent = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void
type ButtonClickEvent = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void

type Props = Omit<HTMLProps<HTMLButtonElement>, 'disabled' | 'state'> & {
  type?: 'button' | 'submit' | 'reset'
  height?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  variant?: ButtonVariant
  state?: ButtonState
  shape?: ButtonShape
  onClick?: AnchorClickEvent | ButtonClickEvent
  testid?: string
  isRounded?: boolean
  isDisabled?: boolean
  isLoading?: boolean

  // Link props
  href?: string
  isLink?: boolean
  isExternal?: boolean
  isOpenedInNewTab?: boolean
}

export const Button = memo((props: Props) => {
  const {
    children,
    className,
    href,
    onClick,
    state = 'filled',
    type = 'button',
    height = 'md',
    variant = 'primary',
    shape = 'rect',
    testid,
    isRounded,
    isDisabled,
    isExternal,
    isLink,
    isLoading,
    isOpenedInNewTab,
    ...rest
  } = props
  const isPrimary = variant === 'primary'
  const isPrimary2 = variant === 'primary-2'
  const isNeutral = variant === 'neutral'
  const isDanger = variant === 'danger'
  let classes = classNames(className, 'text-left outline-none', {
    'pointer-events-none opacity-50': isDisabled || isLoading,
  })

  if (!includes<ButtonState>(['text', 'block', 'raw'], state)) {
    classes = classNames(classes, {
      'px-3': shape === 'rect' && includes(['xs', 'sm'], height),
      'px-4': shape === 'rect' && includes(['md', 'lg', 'xl', '2xl'], height),
      'h-6': height === 'xs',
      'h-8': height === 'sm',
      'h-11': height === 'md',
      'h-14': height === 'lg',
      'h-16': height === 'xl',
      'h-20': height === '2xl',
      'w-6': shape === 'square' && height === 'sm',
      'w-8': shape === 'square' && height === 'sm',
      'w-11': shape === 'square' && height === 'md',
      'w-14': shape === 'square' && height === 'lg',
      'w-16': shape === 'square' && height === 'xl',
      'w-20': shape === 'square' && height === '2xl',
      'rounded-lg': !isRounded,
      'rounded-full': isRounded,
    })
  }

  if (state === 'filled') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center font-semibold', {
      'bg-primary-500 text-primary-900': isPrimary,
      'bg-danger text-white': isDanger,
      'bg-ui-700 text-white': isNeutral,
    })
  }

  if (state === 'outline') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center bg-ui-800 border font-semibold', {
      'border-primary-500 text-primary-500': isPrimary,
      'border-primary-400 text-primary-500 border-opacity-10': isPrimary2,
      'border-danger text-danger': isDanger,
      'border-ui-600 text-white text-opacity-60': isNeutral,
    })
  }

  if (state === 'text') {
    classes = classNames(classes, 'shrink-0 bg-transparent font-normal')
  }

  if (state === 'ghost') {
    classes = classNames(classes, 'inline-flex items-center shrink-0 justify-center font-semibold', {
      'text-primary-500': isPrimary,
      'text-danger': isDanger,
    })
  }

  if (state === 'block') {
    classes = classNames(classes, 'w-full py-2 px-4', {
      'bg-ui-800 w-full py-2 px-4': isNeutral,
    })
  }

  const renderContent = () => {
    if (state === 'text') {
      return <Text variant={variant as TextVariant}>{children}</Text>
    }

    return (
      <>
        {isLoading && <Spinner className="-ml-1 mr-2" />}
        {children}
      </>
    )
  }

  if (isLink && href) {
    if (isExternal) {
      const additionalAttrs: AnchorHTMLAttributes<HTMLAnchorElement> = {}

      if (isOpenedInNewTab) {
        additionalAttrs.target = '_blank'
        additionalAttrs.rel = 'noreferrer'
      }

      return (
        <a {...additionalAttrs} className={classes} href={href} onClick={onClick as AnchorClickEvent} data-testid={testid}>
          {renderContent()}
        </a>
      )
    }

    return (
      <Link className={classes} to={href} onClick={onClick as AnchorClickEvent} data-testid={testid}>
        {renderContent()}
      </Link>
    )
  }

  return (
    <button {...rest} className={classes} type={type} disabled={isDisabled} onClick={onClick as ButtonClickEvent} data-testid={testid}>
      {renderContent()}
    </button>
  )
})
