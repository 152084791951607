import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlTagsInput, GqlTagsQuery, GqlTagsQueryVariables } from '@graphql'
import { TAG_CORE_FIELDS_FRAGMENT } from '@coachmate/tag/graphql/tag-fragment.graphql'
import { PAGINATION_CORE_FIELDS_FRAGMENT } from '@coachmate/common/graphql/pagination-fragment.graphql'

export const TAGS = gql`
  ${TAG_CORE_FIELDS_FRAGMENT}
  ${PAGINATION_CORE_FIELDS_FRAGMENT}

  query Tags($input: TagsInput!) {
    tags(input: $input) {
      items {
        ...CoreTagFields
      }
      pagination {
        ...CorePaginationFields
      }
      sort {
        sortBy
        direction
      }
    }
  }
`

export const tags = async (input: GqlTagsInput) => {
  loggerService.debug('[tags] Getting tags.')

  const { data } = await apolloService.query<GqlTagsQuery, GqlTagsQueryVariables>({
    query: TAGS,
    variables: { input },
    fetchPolicy: 'network-only',
  })

  return data.tags
}
