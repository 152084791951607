import React, { memo } from 'react'
import { GqlCoreTagFieldsFragment } from '@graphql'
import { Td, Tr, Text, Button, useModal } from '@coachmate/common'
import { ArchiveTagModal, EditTagModal, Tag } from '@coachmate/tag'

type Props = {
  tag: GqlCoreTagFieldsFragment
}

export const TagListRow = memo(({ tag }: Props) => {
  const { openModal } = useModal()

  return (
    <Tr>
      <Td>
        <Tag tag={tag} />
      </Td>
      <Td>
        <Text className="text-xs" variant="primary-3">
          {tag.description}
        </Text>
      </Td>
      <Td className="whitespace-nowrap">
        <Button className="mr-4" onClick={() => openModal(<EditTagModal tag={tag} />)} variant="info" state="text">
          Edit
        </Button>
        <Button onClick={() => openModal(<ArchiveTagModal tag={tag} />)} variant="danger" state="text">
          Archive
        </Button>
      </Td>
    </Tr>
  )
})
