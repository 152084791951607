import React, { memo } from 'react'
import { useUser } from '@coachmate/user'
import { AppSideNavLink } from '@coachmate/app'

export const AppSideNav = memo(() => {
  const { isSuperuser, hasRequestAccess } = useUser()

  return (
    <div className="shrink-0 h-full w-72 pt-16 px-8 pb-8 z-side-nav">
      <AppSideNavLink to="/overview">Overview</AppSideNavLink>
      <AppSideNavLink className="mt-4" to="/user/list" activeRoutes={['/user/list', '/user/view/:userId']}>
        Users
      </AppSideNavLink>
      {hasRequestAccess && (
        <AppSideNavLink className="mt-4" to="/user/request-access-list">
          Early Access Request
        </AppSideNavLink>
      )}
      {isSuperuser && (
        <AppSideNavLink className="mt-4" to="/tag/list">
          Tags
        </AppSideNavLink>
      )}
    </div>
  )
})
