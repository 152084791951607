import { makeVar, useReactiveVar } from '@apollo/client'
import { GqlCoreEntityFieldsFragment } from '@graphql'

const entityVar = makeVar<GqlCoreEntityFieldsFragment | null>(null)
const entitiesVar = makeVar<GqlCoreEntityFieldsFragment[]>([])

export const useEntity = () => {
  const entity = useReactiveVar(entityVar)
  const entities = useReactiveVar(entitiesVar)

  return {
    entity,
    entities,
    setEntity: entityVar,
    setEntities: entitiesVar,
  }
}

export const clearReactiveEntity = () => {
  entityVar(null)
  entitiesVar([])
}
