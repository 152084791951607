import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Text } from '@coachmate/common'

type Props = {
  className?: string
  children: ReactNode
}

const BASE_CLASSES = ['text-sm', 'text-left', 'mb-1']

export const Label = memo(({ className, children }: Props) => {
  const classes = classNames(className, BASE_CLASSES)

  return <Text className={classes}>{children}</Text>
})
