import React, { FormEvent, useEffect, useState } from 'react'
import { GqlCoreTagFieldsFragment } from '@graphql'
import { tags, updateTag, useTag } from '@coachmate/tag'
import { Button, Input, Label, Section, sentryService, Text, useAnalytics, useModal, useToast } from '@coachmate/common'

type Props = {
  tag: GqlCoreTagFieldsFragment
}

export const EditTagModal = ({ tag }: Props) => {
  const { setTags } = useTag()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({ value: tag.value, description: tag.description })

  useEffect(() => {
    track('admin_tag_edit_modal_open')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('admin_tag_edit_submit', { tagId: tag.id })
    setIsSaving(true)

    try {
      await updateTag({ tagId: tag.id, ...formData })
      const { items } = await tags({ pagination: { page: 1, pageSize: 100 }, sort: { sortBy: 'created_at', direction: 'desc' } })

      setTags(items)
      closeModal()
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      sentryService.captureException({ exception: error, extras: { tagId: tag.id } })
      track('admin_tag_edit_submit_error', { error: error.message, tagId: tag.id })
    }
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Label>Value</Label>
        <Input
          className="mb-4"
          value={formData.value}
          onChange={({ target }) => setFormData({ ...formData, value: target.value })}
          isDisabled={isSaving}
          isRequired
        />
        <Label>Description</Label>
        <Input
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          isDisabled={isSaving}
          isRequired
        />
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isSaving}>
            Update Tag
          </Button>
        </div>
      </Section>
    </form>
  )
}
