import { gql } from '@apollo/client'

export const ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreEntityTerminologyFields on EntityTerminology {
    drill
    drills
  }
`
export const WHITE_LABEL_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreWhiteLabelFields on WhiteLabel {
    colours {
      primaryContrast
      secondaryContrast
      success
      warning
      danger
      info
      primary {
        _50
        _100
        _200
        _300
        _400
        _500
        _600
        _700
        _800
        _900
      }
      ui {
        _50
        _100
        _200
        _300
        _400
        _500
        _600
        _700
        _800
        _900
      }
      accents {
        base
        dark
        light
      }
    }
  }
`

export const ENTITY_CORE_FIELDS_FRAGMENT = gql`
  ${ENTITY_TERMINOLOGY_CORE_FIELDS_FRAGMENT}
  ${WHITE_LABEL_CORE_FIELDS_FRAGMENT}

  fragment CoreEntityFields on Entity {
    id
    name
    sport
    type
    region
    state
    distribution
    whiteLabel
    coverImage1x2Url
    backgroundImage1x2Url
    sportPickerImage16x9Url
    logoImageUrl
    terminology {
      ...CoreEntityTerminologyFields
    }
    whiteLabelConfig {
      ...CoreWhiteLabelFields
    }
  }
`
