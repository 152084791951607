import classNames from 'classnames'
import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { NumberAnimation, Text, utilService } from '@coachmate/common'

type Props = {
  className?: string
  past: number
  future: number
}

export const OverviewPastFutureBars = memo(({ className, past, future }: Props) => (
  <div className={classNames(className, 'flex items-center justify-start mt-8')}>
    <motion.div
      className="flex flex-col items-start min-w-[10%] mr-2"
      initial={{ width: 0 }}
      animate={{ width: `${(past / (past + future)) * 100 || 10}%` }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center">
        <Text className="text-lg mr-1">
          <NumberAnimation value={past} format={utilService.formatInt} duration={0.5} />
        </Text>
        <Text className="text-xs" variant="primary-3">
          (<NumberAnimation value={(past / (past + future)) * 100 || 0} format={utilService.formatInt} duration={0.5} />
          %)
        </Text>
      </div>
      <div className="rounded-full w-full h-2 bg-primary-800 my-4" />
      <Text className="text-xs mt-1" variant="primary-3">
        Past
      </Text>
    </motion.div>
    <motion.div
      className="flex flex-col items-start min-w-[10%]"
      initial={{ width: 0 }}
      animate={{ width: `${(future / (past + future)) * 100 || 10}%` }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center">
        <Text className="text-lg mr-1">
          <NumberAnimation value={future} format={utilService.formatInt} duration={0.5} />
        </Text>
        <Text className="text-xs" variant="primary-3">
          (<NumberAnimation value={(future / (past + future)) * 100 || 0} format={utilService.formatInt} duration={0.5} />
          %)
        </Text>
      </div>
      <div className="rounded-full w-full h-2 bg-primary-500 my-4" />
      <Text className="text-xs mt-1" variant="primary-3">
        Future
      </Text>
    </motion.div>
  </div>
))
