import { GqlCoreUserFieldsFragment } from '@graphql'
import { makeVar, useReactiveVar } from '@apollo/client'

export const userVar = makeVar<GqlCoreUserFieldsFragment | null>(null)
export const usersVar = makeVar<GqlCoreUserFieldsFragment[]>([])
export const isSuperuserVar = makeVar<boolean>(false)
export const hasRequestAccessVar = makeVar<boolean>(false)

export const useUser = () => {
  const user = useReactiveVar(userVar)
  const users = useReactiveVar(usersVar)
  const isSuperuser = useReactiveVar(isSuperuserVar)
  const hasRequestAccess = useReactiveVar(hasRequestAccessVar)

  return {
    user,
    users,
    isSuperuser,
    hasRequestAccess,
    setUser: userVar,
    setUsers: usersVar,
    setIsSuperuser: isSuperuserVar,
    setHasRequestAccess: hasRequestAccessVar,
  }
}

export const clearReactiveUser = () => {
  userVar(null)
  usersVar([])
  isSuperuserVar(false)
  hasRequestAccessVar(false)
}
