import classNames from 'classnames'
import { every, find, map } from 'lodash'
import React, { useState, useMemo, useCallback, useEffect, createContext, useContext, useRef } from 'react'
import { TabModel, TabButton, TabsActiveIndicator } from '@coachmate/common'

export type TabContextType<T> = {
  tabs: TabModel<T>[]
  activeTab: TabModel<T>
  setTabs: (tabs: TabModel<T>[]) => void
  setActiveTab: (tab: TabModel<T>) => void
  setActiveTabById: (label: string) => void
}

const TabsContext = createContext<TabContextType<any> | null>(null)

export type TabsVariant = 'square-pill' | 'rounded-pill' | 'ghost' | 'underline'

type Props<T> = {
  className?: string
  onChange?: (activeTab: TabModel<T>) => void
  initialTabId?: T | null
  tabs: TabModel<T>[]
  variant?: TabsVariant
  isDisabled?: boolean
  isBlock?: boolean
}

const BASE_CLASSES = ['relative', 'items-center']

export function Tabs<T>({ className, variant = 'square-pill', initialTabId, tabs, onChange, isDisabled, isBlock }: Props<T>) {
  const tabButtonRefs = useRef<HTMLButtonElement[]>([])
  const initialTabById = initialTabId && find(tabs, ({ id }) => Boolean(id && id === initialTabId))
  const [tabsToManage, setTabs] = useState<TabModel<T>[]>(tabs)
  const [activeTab, setActiveTab] = useState<TabModel<T>>(initialTabById || tabs[0])
  const classes = classNames(className, BASE_CLASSES, {
    'bg-ui-800 rounded-md p-0.5': variant === 'square-pill',
    'bg-ui-800 rounded-full p-0.5': variant === 'rounded-pill',
    flex: isBlock,
    'inline-flex': !isBlock,
  })

  useEffect(() => {
    setTabs(tabs)
    tabButtonRefs.current = []
  }, [tabs])

  useEffect(() => {
    handleSetActiveTab(activeTab)
  }, [])

  const handleSetActiveTab = useCallback((activeTab: TabModel<T>) => {
    setActiveTab(activeTab)

    if (onChange) {
      onChange(activeTab)
    }
  }, [])

  const handleSetActiveTabById = useCallback(
    (idToActivate: T) => {
      const tabToActivate = find(tabs, ({ id }) => Boolean(id && id === idToActivate))

      if (tabToActivate) {
        handleSetActiveTab(tabToActivate)
      }
    },
    [tabs]
  )

  const memoizedContextValue = useMemo(
    () => ({
      activeTab,
      setActiveTab: handleSetActiveTab,
      setActiveTabById: handleSetActiveTabById,
      tabs: tabsToManage,
      setTabs,
    }),
    [activeTab, tabsToManage]
  )

  if (!activeTab || !every(tabs, ({ id: idToCheck }) => find(tabsToManage, ({ id }) => id === idToCheck))) {
    return null
  }

  return (
    <TabsContext.Provider value={memoizedContextValue as any}>
      <div className={classes}>
        {variant !== 'ghost' && <TabsActiveIndicator tabButtonRefs={tabButtonRefs} variant={variant} />}
        {map(tabs, (tab, index) => (
          <TabButton<T> key={index} variant={variant} isDisabled={isDisabled} ref={(ref) => ref && tabButtonRefs.current.push(ref)} {...tab} />
        ))}
      </div>
    </TabsContext.Provider>
  )
}

export const useTabs = () => {
  const context = useContext(TabsContext)

  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.')
  }

  return context
}
