import { firebaseService, loggerService } from '@coachmate/common'
import { browserLocalPersistence, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth'

type OnAuthStateChangedParam = (firebaseUser: User | null) => void
type OnIsTokenChangedParam = () => void

class AuthService {
  getAuth = () => {
    return getAuth(firebaseService)
  }

  getFirebaseUser(): Promise<User | null> {
    loggerService.debug('[AuthService] Getting firebase user in state.')

    return new Promise((resolve) => {
      const unsubscribe = this.getAuth().onAuthStateChanged((firebaseUser) => {
        resolve(firebaseUser)
        unsubscribe()
      })
    })
  }

  getToken(): Promise<string> {
    loggerService.debug('[AuthService] Getting firebase token in state.')

    return new Promise((resolve, reject) => {
      this.getAuth().currentUser?.getIdToken(true).then(resolve).catch(reject)
    })
  }

  onAuthStateChanged(callback: OnAuthStateChangedParam) {
    return this.getAuth().onAuthStateChanged(callback)
  }

  onIdTokenChanged(callback: OnIsTokenChangedParam) {
    return this.getAuth().onIdTokenChanged(callback)
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<User> {
    loggerService.debug('[AuthService] Signing user in.')

    return new Promise((resolve, reject) => {
      this.getAuth()
        .setPersistence(browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(this.getAuth(), email, password))
        .then(() => this.getFirebaseUser())
        .then((firebaseUser) => (firebaseUser ? resolve(firebaseUser) : reject()))
        .catch(reject)
    })
  }

  sendResetPasswordEmail(email: string) {
    loggerService.debug('[AuthService] Sending reset password email.')

    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(this.getAuth(), email).then(resolve).catch(reject)
    })
  }

  signOut() {
    loggerService.debug('[AuthService] Signing user out.')

    return new Promise((resolve, reject) => {
      this.getAuth().signOut().then(resolve).catch(reject)
    })
  }
}

export const authService = new AuthService()
