import classNames from 'classnames'
import React, { CSSProperties, memo } from 'react'
import { Text } from '@coachmate/common'
import { GqlCoreTagFieldsFragment } from '@graphql'

type Props = {
  className?: string
  tag: GqlCoreTagFieldsFragment
  onClick: (tag: GqlCoreTagFieldsFragment) => void
  isSelected?: boolean
}

export const TagSelect = memo(({ className, tag, onClick, isSelected }: Props) => {
  const classes = classNames(className, 'inline-flex items-center justify-between border rounded-full px-3 py-1')
  const style: CSSProperties = {
    borderColor: tag.colour,
  }

  if (isSelected) {
    style.backgroundColor = tag.colour
  }

  return (
    <button className={classes} style={style} onClick={() => onClick(tag)} type="button">
      <Text className="text-xs" variant={isSelected ? 'primary-1' : 'primary-3'}>
        {tag.value}
      </Text>
    </button>
  )
})
