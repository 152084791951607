import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { GqlByUserIdInput, GqlSuperuserEnableUserMutation, GqlSuperuserEnableUserMutationVariables } from '@graphql'

const SUPERUSER_ENABLE_USER = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  mutation SuperuserEnableUser($input: ByUserIdInput!) {
    superuserEnableUser(input: $input) {
      ...CoreUserFields
    }
  }
`

export const superuserEnableUser = async (input: GqlByUserIdInput) => {
  loggerService.debug('[superuserEnableUser] Enabling user.')

  const { data } = await apolloService.mutate<GqlSuperuserEnableUserMutation, GqlSuperuserEnableUserMutationVariables>({
    mutation: SUPERUSER_ENABLE_USER,
    variables: { input },
  })

  return data?.superuserEnableUser
}
