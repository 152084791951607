import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { RouterPath } from '@coachmate/router'
import { Button, Text } from '@coachmate/common'

type Props = {
  children: ReactNode
  className?: string
  to: RouterPath
  hasActiveState?: boolean
  activeRoutes?: string[]
  isExternal?: boolean
}

export const AppSideNavLink = memo(({ children, className, to, hasActiveState = true, activeRoutes, isExternal }: Props) => {
  const location = useLocation()
  const classes = classNames(className, 'flex flex-col justify-center items-start whitespace-nowrap rounded-md h-10 px-3 py-2')
  const isActive = Boolean(matchPath(location.pathname, activeRoutes || [to]))
  const handleIsActive = () => isActive

  if (isExternal) {
    return (
      <Button className={classes} state="text" href={to} isOpenedInNewTab isExternal isLink>
        <Text className="text-sm font-semibold" variant="primary-1">
          {children}
        </Text>
      </Button>
    )
  }

  return (
    <NavLink className={classes} activeClassName={hasActiveState ? 'bg-ui-800' : ''} to={to} isActive={activeRoutes && handleIsActive}>
      <Text className="text-sm font-semibold" variant={isActive ? 'primary' : 'primary-1'}>
        {children}
      </Text>
    </NavLink>
  )
})
