import React, { memo } from 'react'
import { Page, Text } from '@coachmate/common'

export const FiveHundredPage = memo(() => (
  <Page isCentered isFullScreen>
    <div className="text-center">
      <Text className="text-center text-xl font-bold">Uh oh!</Text>

      <Text className="text-center mt-2 mb-4">Something unexpected happened.</Text>
    </div>
  </Page>
))
