import { useHistory } from 'react-router-dom'
import React, { FormEvent, useState, memo, useEffect } from 'react'
import { routerService } from '@coachmate/router'
import { FIREBASE_AUTH_ERROR, useAuth } from '@coachmate/auth'
import CoachMateLogoLight from '@assets/svg/coachmate-logo-light.svg'
import { Button, Input, Label, Page, sentryService, Text, useAnalytics } from '@coachmate/common'

export const LoginPage = memo(() => {
  const history = useHistory()
  const { track } = useAnalytics()
  const { login, logout } = useAuth()
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({ email: '', password: '' })

  useEffect(() => {
    ;(async () => {
      track('admin_auth_login_view')
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout()
    })()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')
    setIsSaving(true)

    try {
      track('admin_auth_login_submit')

      await login(formData.email, formData.password)

      history.push(routerService.getHref('/'))
    } catch (error: any) {
      if (error.code === FIREBASE_AUTH_ERROR.userNotFound || error.code === FIREBASE_AUTH_ERROR.wrongPassword) {
        setFormError('Email or password are incorrect.')
      } else {
        track('admin_auth_login_submit_error', { error: error.message })
        setFormError('An unexpected error occurred.')
        sentryService.captureException({ exception: error })
      }

      setIsSaving(false)
    }
  }

  return (
    <Page isCentered isFullScreen>
      <div className="w-full sm:w-96 text-center px-5">
        <div className="flex justify-center">
          <div className="flex flex-col items-end">
            <CoachMateLogoLight className="h-6" />
            <Text className="text-sm font-semibold">Admin</Text>
          </div>
        </div>
        <Text className="font-bold text-xl mt-4">Sign into your account</Text>
        {Boolean(formError) && (
          <Text className="text-sm my-4" variant="danger">
            {formError}
          </Text>
        )}
        <form className="mt-8" onSubmit={handleSubmit}>
          <Label>Email address</Label>
          <Input
            className="mb-4"
            value={formData.email}
            onChange={({ target }) => setFormData({ ...formData, email: target.value })}
            type="email"
            autoComplete="username"
            isError={Boolean(formError)}
            isDisabled={isSaving}
            isRequired
          />
          <Label>Password</Label>
          <Input
            value={formData.password}
            onChange={({ target }) => setFormData({ ...formData, password: target.value })}
            type="password"
            autoComplete="current-password"
            isError={Boolean(formError)}
            isDisabled={isSaving}
            isRequired
          />
          <Button className="mt-4 w-full" type="submit" isLoading={isSaving}>
            Sign in
          </Button>
        </form>
      </div>
    </Page>
  )
})
