import { useHistory } from 'react-router-dom'
import { authService } from '@coachmate/auth'
import { routerService } from '@coachmate/router'
import { APOLLO_ERROR_MESSAGE, localStorageService, loggerService, sentryService } from '@coachmate/common'

type CallbackParam = () => Promise<void>

export const useRunQuery = () => {
  const history = useHistory()

  const runQueryHandler = async (callback: CallbackParam, isRetry = false) => {
    try {
      await callback()
    } catch (error: any) {
      if (error.message === APOLLO_ERROR_MESSAGE.authenticationFailed) {
        if (!isRetry) {
          loggerService.debug('[useRunQuery] Authentication failed for first time. Attempting to refresh token, and retry request.')
          localStorageService.setItem('firebase-token', await authService.getToken())

          await runQueryHandler(callback, true)
        } else {
          loggerService.debug('[useRunQuery] Authentication failed second time. Redirecting user to login screen.')
          history.push(routerService.getHref('/login'))
        }
      } else {
        sentryService.captureException({ exception: error })
        throw error
      }
    }
  }

  return runQueryHandler
}
