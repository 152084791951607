import classNames from 'classnames'
import React, { ReactNode, HTMLProps, memo } from 'react'

type Props = HTMLProps<HTMLTableCellElement> & {
  children: ReactNode
  className?: string
}

export const Td = memo(({ className, children, ...rest }: Props) => {
  const classes = classNames(className, 'text-sm relative p-4 first:pl-0 last:pr-0')

  return (
    <td {...rest} className={classes}>
      {children}
    </td>
  )
})
