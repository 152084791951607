import { map } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { useUser } from '@coachmate/user'
import { useEntity } from '@coachmate/entity'
import { GqlAdminOverviewQuery } from '@graphql'
import { Page, Section, Select, sentryService, Text, useAnalytics, useCommon, useRunQuery } from '@coachmate/common'
import {
  adminOverview,
  OverviewSection,
  OverviewSectionCoachExperience,
  OverviewSectionCustomEventsCreated,
  OverviewSectionDrillRatingBreakdown,
  OverviewSectionEventsCreated,
  OverviewSectionGameEventsCreated,
  OverviewSectionPracticeEventsCreated,
  OverviewSectionPracticePlanRatingBreakdown,
  OverviewSectionPracticePlansBooked,
  OverviewSectionTeamAge,
  OverviewSectionTeamGender,
  OverviewSectionTeamRole,
  OverviewSectionTeamSkill,
  OverviewSectionUserGender,
  OverviewSectionUserIntention,
} from '@coachmate/overview'

export const OverviewPage = memo(() => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { entities } = useEntity()
  const { isSuperuser } = useUser()
  const [entityId, setEntityId] = useState('')
  const { setUi, isUiLoading, isUiSaving } = useCommon()
  const [overview, setOverview] = useState<GqlAdminOverviewQuery['adminOverview']>()
  const entityOptions = [{ label: 'All', value: '' }, ...map(entities, ({ id, name }) => ({ label: name, value: id }))]

  const fetchOverview = async (entityId: string) => {
    try {
      track('admin_overview_view', { entityId })

      await runQuery(async () => {
        setOverview(await adminOverview({ entityId }))
        setUi('ready')
      })
    } catch (error: any) {
      setUi('error')
      track('admin_overview_view_error', { entityId, error: error.message })
      sentryService.captureException({ exception: error, extras: { entityId } })
    }
  }

  useEffect(() => {
    if (isSuperuser) {
      fetchOverview(entityId)
    } else {
      const entityId = entities[0].id

      setEntityId(entityId)
      fetchOverview(entityId)
    }
  }, [])

  if (isUiLoading || !overview) {
    return null
  }

  const handleEntityChange = (entityId: string) => {
    setUi('saving')
    setEntityId(entityId)
    fetchOverview(entityId)
  }

  return (
    <Page>
      <Section className="pt-16 pb-5">
        {isSuperuser && (
          <Select className="w-1/4 mb-8" options={entityOptions} onChange={handleEntityChange} value={entityId} isDisabled={isUiSaving} />
        )}

        <Text className="text-4xl font-semibold">Overview</Text>
        <OverviewSection overview={overview} />

        <Text className="text-2xl font-semibold mt-16">Coaches</Text>
        <OverviewSectionCoachExperience overview={overview} />
        <OverviewSectionUserGender overview={overview} />
        <OverviewSectionUserIntention overview={overview} />

        <Text className="text-2xl font-semibold mt-16">Teams</Text>
        <OverviewSectionTeamGender overview={overview} />
        <OverviewSectionTeamSkill overview={overview} />
        <OverviewSectionTeamAge overview={overview} />
        <OverviewSectionTeamRole overview={overview} />

        <Text className="text-2xl font-semibold mt-16">Events</Text>
        <OverviewSectionEventsCreated overview={overview} />
        <OverviewSectionPracticeEventsCreated overview={overview} />
        <OverviewSectionGameEventsCreated overview={overview} />
        <OverviewSectionCustomEventsCreated overview={overview} />
        <OverviewSectionPracticePlansBooked overview={overview} />

        {entityId && (
          <>
            <Text className="text-2xl font-semibold mt-16">Content</Text>
            <OverviewSectionDrillRatingBreakdown overview={overview} />
            <OverviewSectionPracticePlanRatingBreakdown overview={overview} />
          </>
        )}
      </Section>
    </Page>
  )
})
