import { compact, map, max, orderBy } from 'lodash'
import React, { Fragment, memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { NumberAnimation, ProgressBar, Text, utilService } from '@coachmate/common'
import { OverviewCard, OverviewPastFutureBars } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionPracticePlansBooked = memo(({ overview }: Props) => {
  const maxBookCount = max(map(overview.practicePlansBookedBreakdown, 'count')) || 0

  return (
    <OverviewCard className="mt-5" heading="Practice plans booked">
      <Text className="text-4xl mt-2">
        <NumberAnimation value={overview.practicePlansBooked} format={utilService.formatInt} duration={0.5} />
      </Text>
      <OverviewPastFutureBars past={overview.practicePlansBookedPast} future={overview.practicePlansBookedFuture} />

      {Boolean(maxBookCount) && (
        <>
          <Text className="mt-8">Breakdown</Text>
          {map(orderBy(compact(overview.practicePlansBookedBreakdown), 'count', 'desc'), ({ name, count }, index) => (
            <Fragment key={index}>
              {Boolean(count) && (
                <div key={index} className="relative flex items-center mt-8">
                  <Text className="absolute text-xs -top-5" variant="primary-2">
                    {name}
                  </Text>
                  <ProgressBar className="flex items-center w-full" total={maxBookCount} completed={count} isZeroVisible>
                    <Text className="text-xs ml-2" variant="primary-3">
                      <NumberAnimation value={count} format={(value) => utilService.formatInt(value, 2)} duration={0.5} />
                    </Text>
                  </ProgressBar>
                </div>
              )}
            </Fragment>
          ))}
        </>
      )}
    </OverviewCard>
  )
})
