import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { NumberAnimation, Text, utilService } from '@coachmate/common'
import { OverviewCard, OverviewPastFutureBars } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionPracticeEventsCreated = memo(({ overview }: Props) => (
  <OverviewCard className="mt-5" heading="Practice events created">
    <Text className="text-4xl mt-2 mb-5">
      <NumberAnimation value={overview.practiceEventsCreated} format={utilService.formatInt} duration={0.5} />
    </Text>
    <OverviewPastFutureBars past={overview.practiceEventsCreatedPast} future={overview.practiceEventsCreatedFuture} />
  </OverviewCard>
))
