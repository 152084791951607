import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode, useRef, MutableRefObject, memo, useCallback, useEffect } from 'react'
import { TRANSITION_DURATION } from '@coachmate/common'

type Props = {
  children: ReactNode
  className?: string
  id?: string
  isOpen?: boolean
  onClickOutside?: () => void
  onClose?: () => void
  toggleRef?: MutableRefObject<any>
}

const BASE_CLASSES = ['absolute', 'rounded-md', 'shadow-lg', 'border', 'border-ui-500', 'bg-ui-700', 'z-dropdown']
const BASE_CARAT_CLASSES = [
  'absolute',
  'bg-ui-700',
  'border-ui-500',
  'border-solid',
  'border-t',
  'border-l',
  'border-r-0',
  'border-b-0',
  'rotate-45',
  'w-4',
  'h-4',
  'z-1',
]

export const Dropdown = memo(({ id, children, className, isOpen, onClickOutside, onClose, toggleRef }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const classes = classNames(className, BASE_CLASSES, {
    visible: isOpen,
    hidden: !isOpen,
  })

  const handleClickOutside = useCallback(({ target }: MouseEvent | TouchEvent) => {
    if (!dropdownRef.current || dropdownRef.current.contains(target as any) || (toggleRef?.current && toggleRef.current.contains(target))) {
      return
    }

    if (onClickOutside) {
      onClickOutside()
    }
  }, [])

  const handleKeydown = useCallback(({ code }: KeyboardEvent) => {
    if (code === 'Escape' && onClose) {
      onClose()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    window.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [dropdownRef, onClickOutside, toggleRef])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          id={id}
          className={classes}
          ref={dropdownRef}
          hidden={!isOpen}
          initial={{ y: -5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: TRANSITION_DURATION.md }}
          exit={{ y: -5, opacity: 0 }}
        >
          <div className={classNames(BASE_CARAT_CLASSES)} style={{ top: '-9px', right: '24px' }} />
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
})
