import React, { memo } from 'react'
import { map, find } from 'lodash'
import { routerService } from '@coachmate/router'
import { GqlCoreAdminUserFieldsFragment } from '@graphql'
import { useUser } from '@coachmate/user'
import { Td, Tr, Text, Icon, Button, useCommon, utilService, useAnalytics, useRunQuery, useToast, sentryService } from '@coachmate/common'
import { Tag, useTag } from '@coachmate/tag'
import { approveEntityAccount } from '@coachmate/account'

type Props = {
  adminUser: GqlCoreAdminUserFieldsFragment
  refetch: () => Promise<void>
}

export const UserListRowRequestAccess = memo(({ adminUser, refetch }: Props) => {
  const runQuery = useRunQuery()
  const { tags } = useTag()
  const { isSuperuser } = useUser()
  const { isUiSaving, setUi } = useCommon()
  const { track } = useAnalytics()
  const { openToast } = useToast()

  const approveRequest = async () => {
    const [requestTagId] = adminUser.tags
    if (!requestTagId) return

    track('admin_user_enrol_in_sport_submit', { userId: adminUser.user.id, entityId: requestTagId })
    setUi('saving')

    try {
      await runQuery(async () => {
        await approveEntityAccount({ userId: adminUser.user.id, requestTagId: requestTagId })
        refetch()
        openToast(<Text>Access has been approved</Text>, 'success')
      })
    } catch (error: any) {
      setUi('ready')
      openToast(<Text>Something went wrong</Text>, 'danger')
      sentryService.captureException({ exception: error, extras: { tagId: requestTagId } })
      track('admin_user_enrol_in_sport_submit_error', { userId: adminUser.user.id, tagId: requestTagId, error: error.message })
    }
  }
  return (
    <Tr>
      <Td>
        <Button
          variant="info"
          href={routerService.getHref('/user/view/:userId', { userId: adminUser.user.id })}
          state="text"
          isDisabled={isUiSaving}
          isLink
        >
          {utilService.getFullName(adminUser.user)}
          {isSuperuser && adminUser.isSuperuser && ' (Superuser)'}
        </Button>
        <Text className="text-xs" variant="primary-3">
          {adminUser.user.email}
        </Text>
      </Td>
      {isSuperuser && (
        <Td>
          {map(adminUser.tags, (tagId) => {
            const tag = find(tags, { id: tagId })
            return tag ? <Tag key={tag.id} className="mr-1 mb-1 truncate" tag={tag} /> : null
          })}
        </Td>
      )}
      <Td className="text-right">
        <Button height="sm" state="outline" className="bg-transparent" onClick={approveRequest} variant="primary-2" isDisabled={isUiSaving}>
          <Text className="text-xs">Approve</Text> <Icon className="ml-2" icon="check" variant="success" />
        </Button>
      </Td>
    </Tr>
  )
})
