import classNames from 'classnames'
import React, { forwardRef, ReactNode } from 'react'

type Props = {
  className?: string
  children?: ReactNode
  isCentered?: boolean
  isFullScreen?: boolean
}

export const Page = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, children, isCentered, isFullScreen } = props
  const classes = classNames(className, 'relative font-wl-font-family min-h-screen w-full overflow-x-hidden z-page scrollbar-hide', {
    'flex items-center justify-center': isCentered,
    'h-full': isFullScreen,
  })

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  )
})
