import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionTeamGender = memo(({ overview }: Props) => {
  const teamGenders = [
    { label: 'Female', count: 0, percentage: 0 },
    { label: 'Male', count: 0, percentage: 0 },
    { label: 'Mixed', count: 0, percentage: 0 },
  ]

  let totalTeamGenderCount = 0
  forEach(overview.teamGenders, ({ count }) => {
    totalTeamGenderCount += count
  })

  forEach(overview.teamGenders, ({ gender, count }) => {
    if (gender === 'female') {
      teamGenders[0].count = count
      teamGenders[0].percentage = (count / totalTeamGenderCount) * 100
    } else if (gender === 'male') {
      teamGenders[1].count = count
      teamGenders[1].percentage = (count / totalTeamGenderCount) * 100
    } else if (gender === 'mixed') {
      teamGenders[2].count = count
      teamGenders[2].percentage = (count / totalTeamGenderCount) * 100
    }
  })

  return (
    <OverviewCard className="mt-5" heading="Team gender">
      <OverviewBreakdownBars className="mt-5" breakdown={teamGenders} />
    </OverviewCard>
  )
})
