import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'
import { AuthProvider } from '@coachmate/auth'
import { apolloService, ModalProvider, ToastProvider } from '@coachmate/common'

const jsx = (
  <BrowserRouter>
    <ApolloProvider client={apolloService}>
      <ToastProvider>
        <AuthProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </AuthProvider>
      </ToastProvider>
    </ApolloProvider>
  </BrowserRouter>
)

createRoot(document.getElementById('coachmate-admin') as HTMLElement).render(jsx)
