import { gql } from '@apollo/client'

export const ACCOUNT_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreAccountFields on Account {
    id
    userId
    entityId
    type
    isAdmin
    metadata
  }
`
