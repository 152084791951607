import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { Modal } from '@coachmate/common'

export type ModalContextType = {
  closeModal: () => void
  modal: ReactNode
  openModal: (modal: ReactNode) => void
}

export const ModalContext = createContext<ModalContextType | null>(null)

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ModalContextType['modal']>(null)

  const value = useMemo(
    () => ({
      closeModal: () => setModal(null),
      modal,
      openModal: (modal: ReactNode) => setModal(modal),
    }),
    [modal]
  )

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('This component must be used within a <ModalProvider> component.')
  }

  return context
}
