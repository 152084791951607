import React, { useState, useEffect, MutableRefObject } from 'react'
import { findIndex } from 'lodash'
import { TabsVariant, useTabs } from '@coachmate/common'
import classNames from 'classnames'

type Props = {
  tabButtonRefs: MutableRefObject<HTMLButtonElement[]>
  variant: TabsVariant
}

const BASE_CLASSES = ['absolute', 'w-full']
const BASE_CONTENT_CLASSES = ['absolute', 'h-full', 'duration-300', 'ease-in-out']

export function TabsActiveIndicator({ tabButtonRefs, variant }: Props) {
  const { activeTab, tabs } = useTabs()
  const [style, setStyle] = useState<{ left: string; width: string }>()
  const classes = classNames(BASE_CLASSES, {
    'bottom-0 h-1': variant === 'underline',
    'top-0.5 bottom-0.5': variant === 'square-pill' || variant === 'rounded-pill',
  })
  const contentClasses = classNames(BASE_CONTENT_CLASSES, {
    'bg-info': variant === 'underline',
    'bg-white bg-opacity-40 rounded-md': variant === 'square-pill',
    'bg-white bg-opacity-40 rounded-full': variant === 'rounded-pill',
  })

  useEffect(() => {
    const activeIndex = findIndex(tabs, ({ id }) => id === activeTab.id)
    const activeTabButtonRef = tabButtonRefs.current[activeIndex]

    if (!activeTabButtonRef) {
      return
    }

    let left = 0

    for (let i = 0; i < activeIndex; i += 1) {
      const tabButtonWidth = tabButtonRefs.current[i]?.getBoundingClientRect().width || 0

      left += tabButtonWidth

      if (variant === 'underline') {
        // If this is the underline variant, adding 56px to left to account for the margin left on the tab buttons.
        left += 24
      } else if (variant === 'square-pill' || variant === 'rounded-pill') {
        // Adding 4px to account for the margin right on the tab buttons,
        left += 4
      }
    }

    let width = 0
    if (variant === 'underline') {
      // With the underline variant, we want the width of the border bottom to be the same as the text. So we use the bounding client rectangle of the
      // first child.
      width = activeTabButtonRef.children[0].getBoundingClientRect().width || 0
    } else if (variant === 'square-pill' || variant === 'rounded-pill') {
      width = activeTabButtonRef.getBoundingClientRect().width || 0
    }

    setStyle({ left: `${left}px`, width: `${width}px` })
  }, [tabButtonRefs, activeTab, tabs])

  if (!style) {
    return null
  }

  return (
    <div className={classes}>
      <div className={contentClasses} style={style} />
    </div>
  )
}
