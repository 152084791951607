import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type BadgeVariant = 'success' | 'danger' | 'info' | 'warning' | 'neutral' | 'primary'
type Props = {
  className?: string
  children: ReactNode
  state?: 'opaque' | 'solid'
  variant?: BadgeVariant
  size?: 'sm' | 'md'
  isRounded?: boolean
}

const BASE_CLASSES = ['inline-flex', 'items-center', 'justify-center', 'px-2', 'py-0.5']

export const Badge = ({ className, size = 'sm', children, state = 'opaque', variant = 'success', isRounded = true }: Props) => {
  const classes = classNames(className, BASE_CLASSES, {
    'rounded-full': isRounded,
    'rounded-md': !isRounded,
    'text-xs': size === 'sm',
    'text-sm': size === 'md',
    'bg-success': variant === 'success',
    'bg-danger': variant === 'danger',
    'bg-warning': variant === 'warning',
    'bg-info': variant === 'info',
    'bg-ui-500': variant === 'neutral',
    'bg-primary-700': variant === 'primary',
    'bg-opacity-50 text-white text-opacity-90': state === 'opaque',
    'text-white': state === 'solid',
  })

  return <div className={classes}>{children}</div>
}
