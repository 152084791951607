import React, { FormEvent, useEffect, useState } from 'react'
import { createTag, tags, useTag } from '@coachmate/tag'
import { Button, Input, Label, Section, sentryService, Text, useAnalytics, useModal, useToast } from '@coachmate/common'

const PRESET_COLOURS = [
  '#BC5090',
  '#571845',
  '#900C3E',
  '#C70039',
  '#FF6362',
  '#DF614A',
  '#FF5733',
  '#FF533E',
  '#F79A72',
  '#4F27C6',
  '#1991EB',
  '#29C3CB',
  '#27C6A4',
  '#6BA393',
  '#07705A',
  '#049920',
  '#27C637',
  '#00CF66',
  '#108896',
  '#7268A6',
  '#7727C6',
  '#9E27C6',
  '#58508D',
  '#2E4249',
  '#FFA602',
  '#FFC300',
  '#FEDC7C',
  '#86A3C3',
]

export const CreateTagModal = () => {
  const { setTags } = useTag()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({ value: '', description: '' })

  useEffect(() => {
    track('admin_tag_create_modal_open')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('admin_tag_create_submit')
    setIsSaving(true)

    try {
      await createTag({ ...formData, colour: PRESET_COLOURS[Math.floor(Math.random() * PRESET_COLOURS.length)] })
      const { items } = await tags({ pagination: { page: 1, pageSize: 100 }, sort: { sortBy: 'created_at', direction: 'desc' } })

      setTags(items)
      closeModal()
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      sentryService.captureException({ exception: error })
      track('admin_tag_create_submit_error', { error: error.message })
    }
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Label>Value</Label>
        <Input
          className="mb-4"
          value={formData.value}
          onChange={({ target }) => setFormData({ ...formData, value: target.value })}
          isDisabled={isSaving}
          isRequired
        />
        <Label>Description</Label>
        <Input
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          isDisabled={isSaving}
          isRequired
        />
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isSaving}>
            Create Tag
          </Button>
        </div>
      </Section>
    </form>
  )
}
