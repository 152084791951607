import { map } from 'lodash'
import classNames from 'classnames'
import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { NumberAnimation, Text, utilService } from '@coachmate/common'

export type BreakdownItem = {
  label: string
  count: number
  percentage: number
}

type Props = {
  className?: string
  breakdown: BreakdownItem[]
}

export const OverviewBreakdownBars = memo(({ className, breakdown }: Props) => (
  <div className={classNames(className, 'flex items-center justify-start')}>
    {map(breakdown, ({ label, count, percentage }, index) => (
      <motion.div
        key={label}
        className={classNames('flex flex-col items-start min-w-[15%]', { 'ml-2': index })}
        initial={{ width: 0 }}
        animate={{ width: `${percentage || 100}%` }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center">
          <Text className="text-lg mr-1">
            <NumberAnimation value={count} format={utilService.formatInt} duration={0.5} />
          </Text>
          <Text className="text-xs" variant="primary-3">
            (<NumberAnimation value={percentage} format={utilService.formatInt} duration={0.5} />
            %)
          </Text>
        </div>
        <div className="rounded-full w-full h-2 bg-primary-500 my-4" />
        <Text className="text-xs mt-1" variant="primary-3">
          {label}
        </Text>
      </motion.div>
    ))}
  </div>
))
