import { makeVar, useReactiveVar } from '@apollo/client'

export type UiType = 'ready' | 'loading' | 'saving' | 'error' | null

const uiVar = makeVar<UiType>(null)

export const clearReactiveCommon = () => {
  uiVar(null)
}

export const useCommon = () => {
  const ui = useReactiveVar(uiVar)

  return {
    ui,
    isUiReady: ui === 'ready',
    isUiLoading: ui === 'loading',
    isUiSaving: ui === 'saving',
    isUiError: ui === 'error',

    setUi: uiVar,
  }
}
