import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionTeamSkill = memo(({ overview }: Props) => {
  const teamSkills = [
    { label: 'Beginner', count: 0, percentage: 0 },
    { label: 'Intermediate', count: 0, percentage: 0 },
    { label: 'Advanced', count: 0, percentage: 0 },
  ]

  let totalTeamSkillCount = 0
  forEach(overview.teamSkills, ({ count }) => {
    totalTeamSkillCount += count
  })

  forEach(overview.teamSkills, ({ skill, count }) => {
    if (skill === 'beginner') {
      teamSkills[0].count = count
      teamSkills[0].percentage = (count / totalTeamSkillCount) * 100
    } else if (skill === 'intermediate') {
      teamSkills[1].count = count
      teamSkills[1].percentage = (count / totalTeamSkillCount) * 100
    } else if (skill === 'advanced') {
      teamSkills[2].count = count
      teamSkills[2].percentage = (count / totalTeamSkillCount) * 100
    }
  })

  return (
    <OverviewCard className="mt-5" heading="Player skill level">
      <OverviewBreakdownBars className="mt-5" breakdown={teamSkills} />
    </OverviewCard>
  )
})
