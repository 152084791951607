import { GqlCorePaginationFieldsFragment } from '@graphql'

class PaginationService {
  getDisplayValue = ({ page, pageSize, itemCount, totalCount }: GqlCorePaginationFieldsFragment) => {
    const base = (page - 1) * pageSize

    return `Showing ${base + 1} to ${base + itemCount} of ${totalCount} results`
  }
}

export const paginationService = new PaginationService()
