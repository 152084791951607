import React, { memo } from 'react'
import { Td, Tr, TextSkeleton } from '@coachmate/common'

export const UserListRowSkeleton = memo(() => (
  <Tr>
    <Td>
      <div className="flex flex-col">
        <TextSkeleton className="h-4 w-20" />
        <TextSkeleton className="h-3 w-40 mt-2" />
      </div>
    </Td>
    <Td>
      <TextSkeleton className="h-5 w-12 rounded-full" />
    </Td>
    <Td className="text-center">
      <TextSkeleton className="h-5 w-20" />
    </Td>
    <Td className="text-center">
      <TextSkeleton className="h-5 w-20" />
    </Td>
    <Td>
      <TextSkeleton className="h-3 w-6" />
    </Td>
  </Tr>
))
