import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { ADMIN_USER_CORE_FIELDS_FRAGMENT } from './admin-user-fragment.graphql'
import { PAGINATION_CORE_FIELDS_FRAGMENT } from '@coachmate/common/graphql/pagination-fragment.graphql'
import { GqlAdminUserListInput, GqlAdminUserListQuery, GqlAdminUserListQueryVariables } from '@graphql'

export const ADMIN_USER_LIST = gql`
  ${ADMIN_USER_CORE_FIELDS_FRAGMENT}
  ${PAGINATION_CORE_FIELDS_FRAGMENT}

  query AdminUserList($input: AdminUserListInput!) {
    adminUserList(input: $input) {
      items {
        ...CoreAdminUserFields
      }
      pagination {
        ...CorePaginationFields
      }
      sort {
        sortBy
        direction
      }
    }
  }
`

export const adminUserList = async (input: GqlAdminUserListInput) => {
  loggerService.debug('[adminUserList] Get admin users.')

  const { data } = await apolloService.query<GqlAdminUserListQuery, GqlAdminUserListQueryVariables>({
    query: ADMIN_USER_LIST,
    variables: { input },
    fetchPolicy: 'network-only',
  })

  return data.adminUserList
}
