import { GqlCoreTagFieldsFragment } from '@graphql'
import { makeVar, useReactiveVar } from '@apollo/client'

export const tagVar = makeVar<GqlCoreTagFieldsFragment | null>(null)
export const tagsVar = makeVar<GqlCoreTagFieldsFragment[]>([])

export const useTag = () => {
  const tag = useReactiveVar(tagVar)
  const tags = useReactiveVar(tagsVar)

  return {
    tag,
    tags,
    setTag: tagVar,
    setTags: tagsVar,
  }
}

export const clearReactiveTag = () => {
  tagVar(null)
  tagsVar([])
}
