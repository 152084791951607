import { find, map } from 'lodash'
import React, { memo, useState } from 'react'
import { useUser } from '@coachmate/user'
import { useEntity } from '@coachmate/entity'
import { AppSideNavLink } from '@coachmate/app'
import { routerService } from '@coachmate/router'
import CoachMateLogoLight from '@assets/svg/coachmate-logo-light.svg'
import { Avatar, Badge, Button, Dropdown, DropdownContent, Header, Icon, Select, Text, utilService } from '@coachmate/common'

export const AppHeader = memo(() => {
  const { user, isSuperuser } = useUser()
  const { entity, entities, setEntity } = useEntity()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const renderAdminEntity = () => {
    if (isSuperuser || !entity) {
      return null
    }

    if (entities.length > 1) {
      return (
        <Select
          options={map(entities, ({ id, name }) => ({ label: name, value: id }))}
          onChange={(id) => setEntity(find(entities, { id }) || entities[0])}
          value={entity.id}
        />
      )
    }

    return <img className="h-8" src={entity.logoImageUrl} alt="Entity Logo" />
  }

  return (
    <Header className="bg-ui-800">
      <div className="flex items-center justify-between w-full h-full">
        <div className="flex items-center h-full mr-16">
          <AppSideNavLink className="items-center mr-12" to="/" hasActiveState={false}>
            <div className="flex items-center justify-center">
              <CoachMateLogoLight className="h-6 mr-4" />
              <Badge variant="primary" size="md" state="solid" isRounded={false}>
                Admin
              </Badge>
            </div>
          </AppSideNavLink>
          {renderAdminEntity()}
        </div>

        {user && (
          <div className="relative">
            <Button className="flex items-center justify-center" onClick={() => setIsDropdownOpen(true)} state="raw">
              <Avatar className="mr-2" size="md">
                {utilService.getInitials(user)}
              </Avatar>
              <Text>{utilService.getFullName(user)}</Text>
            </Button>
            <Dropdown className="top-14 -right-2" onClickOutside={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent to={routerService.getHref('/logout')}>
                <div className="flex items-center">
                  <Icon className="mr-2" icon="logout-outlined" variant="primary-3" />
                  <Text>Logout</Text>
                </div>
              </DropdownContent>
            </Dropdown>
          </div>
        )}
      </div>
    </Header>
  )
})
