import React, { memo } from 'react'
import { Page, Section, Text } from '@coachmate/common'

export const FourOhFourPage = memo(() => (
  <Page isCentered isFullScreen>
    <Section>
      <Text className="text-center text-5xl font-semibold">404</Text>

      <Text className="text-center mt-8">Looks like you&apos;ve landed somewhere that doesn&apos;t exist!</Text>
    </Section>
  </Page>
))
