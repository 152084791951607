import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionCoachExperience = memo(({ overview }: Props) => {
  const coachingExperiences = [
    { label: 'Never coached before', count: 0, percentage: 0 },
    { label: '1 - 2 seasons', count: 0, percentage: 0 },
    { label: '3 - 4 seasons', count: 0, percentage: 0 },
    { label: '5+ seasons', count: 0, percentage: 0 },
  ]

  let totalCoachingExperienceCount = 0
  forEach(overview.coachingExperiences, ({ count }) => {
    totalCoachingExperienceCount += count
  })

  forEach(overview.coachingExperiences, ({ experience, count }) => {
    if (experience === '0') {
      coachingExperiences[0].count = count
      coachingExperiences[0].percentage = (count / totalCoachingExperienceCount) * 100
    } else if (experience === '1 - 2') {
      coachingExperiences[1].count = count
      coachingExperiences[1].percentage = (count / totalCoachingExperienceCount) * 100
    } else if (experience === '3 - 4') {
      coachingExperiences[2].count = count
      coachingExperiences[2].percentage = (count / totalCoachingExperienceCount) * 100
    } else if (experience === '5+') {
      coachingExperiences[3].count = count
      coachingExperiences[3].percentage = (count / totalCoachingExperienceCount) * 100
    }
  })

  return (
    <OverviewCard className="mt-5" heading="Coaching experience">
      <OverviewBreakdownBars className="mt-5" breakdown={coachingExperiences} />
    </OverviewCard>
  )
})
