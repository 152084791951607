import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Button, Text } from '@coachmate/common'

type Props = {
  className?: string
  children: ReactNode
  size?: 'sm' | 'md'
  onClick?: () => void
}

export const Avatar = memo(({ className, children, size = 'sm', onClick }: Props) => {
  const classes = classNames(className, 'flex items-center justify-center shrink-0 bg-ui-700 rounded-full', {
    'h-8 w-8': size === 'sm',
    'h-12 w-12': size === 'md',
  })
  const textClasses = classNames({
    'text-sm': size === 'sm',
    ' font-semibold text-lg': size === 'md',
  })

  const renderContent = () => (
    <Text className={textClasses} variant="primary-3">
      {children}
    </Text>
  )

  return onClick ? (
    <Button className={classes} onClick={onClick} state="raw">
      {renderContent()}
    </Button>
  ) : (
    <div className={classes}>{renderContent()}</div>
  )
})
