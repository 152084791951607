import { apiService } from './api.service'
import { utilService } from './util.service'
import { AppEventName } from '@coachmate/app'
import { loggerService } from './logger.service'
import { GqlCoreUserFieldsFragment } from '@graphql'
import { OverviewEventName } from '@coachmate/overview'
import { TagEventName } from '@coachmate/tag/tag.events'
import { AuthEventName } from '@coachmate/auth/auth.events'
import { UserEventName } from '@coachmate/user/user.events'
import { TIMEZONE_CONFIG_BY_TIMEZONE, COUNTRY_BY_COUNTRY_CODE } from '@coachmate/common'

export type AnalyticsEventName = AppEventName | AuthEventName | OverviewEventName | TagEventName | UserEventName

type CachedTrackEvent = {
  event: AnalyticsEventName
  additionalProperties?: Record<string, any>
}

class AnalyticsService {
  private _context = {
    ip: '',
    user_agent: navigator.userAgent,
    timezone: '',
    location: {
      country: '',
    },
    os: {
      name: '',
    },
    screen: {
      width: 0,
      height: 0,
    },
  }
  private _name = ''
  private _email = ''
  private _userId = ''
  private _anonymousId = ''
  private _isIdentified = false
  private _isInitialised = false
  private _cachedTrackEvents: CachedTrackEvent[] = []

  init = async () => {
    loggerService.debug('[<AnalyticsService>] Initialising analytics service.')
    const ipResponse = await apiService.get<{ ip: string }>('https://api.ipify.org/?format=json', true)

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timezoneConfig = TIMEZONE_CONFIG_BY_TIMEZONE[timezone]
    const countryCode = timezoneConfig?.c ? timezoneConfig.c[0] || '' : ''
    const country = countryCode ? COUNTRY_BY_COUNTRY_CODE[countryCode] : ''

    this._anonymousId = utilService.generateUid()
    this._context.ip = ipResponse?.ip || ''
    this._context.timezone = timezone
    this._context.location = { country: country }
    this._context.screen.height = window.innerHeight
    this._context.screen.width = window.innerWidth

    const userAgent = navigator.userAgent || navigator.vendor
    if (/windows phone/i.test(userAgent)) {
      this._context.os.name = 'Windows Phone'
    } else if (/android/i.test(userAgent)) {
      this._context.os.name = 'Android'
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      this._context.os.name = 'iOS'
    } else {
      this._context.os.name = navigator.platform || 'unknown'
    }

    this._isInitialised = true
  }

  post = async (path: string, config: Record<string, any>) => {
    try {
      await apiService.post(path, config)
    } catch (error: any) {
      // We are just swallowing these errors, as we don't need them to be tracked by Sentry.
      loggerService.error(`Failed to call <${path}>. Continuing.`)
    }
  }

  identify = async ({ id, email, firstName, lastName }: GqlCoreUserFieldsFragment, force?: boolean) => {
    if (!this._isInitialised || (this._isIdentified && !force)) {
      return
    }

    this._userId = id
    this._email = email
    this._name = utilService.getFullName({ firstName, lastName })

    await this.post(`${process.env.API_DOMAIN}/analytics/identify`, { ...this.getBaseConfig(), traits: { name: this._name, email: this._email } })

    await this.post(`${process.env.API_DOMAIN}/analytics/alias`, {
      properties: this.getBaseProperties(),
      context: this._context,
      previousId: this._anonymousId,
      userId: this._userId,
    })

    this._isIdentified = true

    // Fire any cached track events once we've identified.
    await utilService.asyncForEach(this._cachedTrackEvents, async ({ event, additionalProperties }) => {
      await this.track(event, additionalProperties)
    })
  }

  track = async (event: AnalyticsEventName, additionalProperties?: Record<string, any>) => {
    if (!this._isIdentified) {
      // Cache the track event, so we can fire it right after the identify event has completed.
      this._cachedTrackEvents.push({ event, additionalProperties })
      return
    }

    await this.post(`${process.env.API_DOMAIN}/analytics/track`, { ...this.getBaseConfig(additionalProperties), event })
  }

  getBaseConfig = (additionalProperties?: Record<string, any>) => {
    const config: Record<string, any> = {
      anonymousId: this._anonymousId,
      properties: this.getBaseProperties(additionalProperties),
      context: this._context,
    }

    if (this._userId) {
      config.userId = this._userId
    }

    return config
  }

  getBaseProperties = (additionalProperties?: Record<string, any>) => {
    const properties: Record<string, any> = {
      ...additionalProperties,
      path: location.pathname,
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url: location.href,
    }

    return properties
  }
}

export const analyticsService = new AnalyticsService()
