import { map, max, orderBy } from 'lodash'
import React, { Fragment, memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewCard } from '@coachmate/overview'
import { NumberAnimation, ProgressBar, Text, utilService } from '@coachmate/common'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionDrillRatingBreakdown = memo(({ overview }: Props) => {
  const maxAverage = max(map(overview.drillRatingBreakdown, 'average')) || 0

  return (
    <OverviewCard className="mt-5" heading="Average drill ratings">
      {Boolean(maxAverage) && (
        <>
          {map(orderBy(overview.drillRatingBreakdown, 'average', 'desc'), ({ name, average }, index) => (
            <Fragment key={index}>
              {Boolean(average) && (
                <div key={index} className="relative flex items-center mt-8">
                  <Text className="absolute text-xs -top-5" variant="primary-2">
                    {name}
                  </Text>
                  <ProgressBar className="flex items-center w-full" total={5} completed={average} isZeroVisible>
                    <Text className="text-xs ml-2" variant="primary-3">
                      <NumberAnimation value={average} format={(value) => utilService.formatInt(value, 2)} duration={0.5} />
                    </Text>
                  </ProgressBar>
                </div>
              )}
            </Fragment>
          ))}
        </>
      )}

      {!maxAverage && (
        <Text className="text-center mt-4" variant="primary-3">
          No drill ratings
        </Text>
      )}
    </OverviewCard>
  )
})
