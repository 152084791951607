import classNames from 'classnames'
import { TextVariant } from '@coachmate/common'

class ComponentService {
  getTextClasses = (variant: TextVariant) => {
    return classNames({
      'text-white': variant === 'primary-1',
      'text-white text-opacity-90': variant === 'primary-2',
      'text-white text-opacity-60': variant === 'primary-3',
      'text-white text-opacity-40': variant === 'primary-4',
      'text-black': variant === 'secondary-1',
      'text-black text-opacity-90': variant === 'secondary-2',
      'text-black text-opacity-60': variant === 'secondary-3',
      'text-black text-opacity-40': variant === 'secondary-4',
      'text-primary-500': variant === 'primary',
      'text-info': variant === 'info',
      'text-danger': variant === 'danger',
      'text-success': variant === 'success',
      'text-warning': variant === 'warning',
    })
  }
}

export const componentService = new ComponentService()
