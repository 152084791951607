import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { find, join, map, upperFirst } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { TeamPill } from '@coachmate/team'
import { useEntity } from '@coachmate/entity'
import { AppErrorPage } from '@coachmate/app'
import { adminUserView } from '@coachmate/admin'
import { GqlCoreAdminUserFieldsFragment } from '@graphql'
import {
  Badge,
  DATE_TIME_FORMAT,
  Icon,
  Page,
  Section,
  sentryService,
  Text,
  useAnalytics,
  useCommon,
  useRunQuery,
  utilService,
} from '@coachmate/common'

export const UserViewPage = memo(() => {
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { entities } = useEntity()
  const { userId } = useParams<{ userId: string }>()
  const { setUi, isUiError, isUiLoading } = useCommon()
  const [adminUser, setAdminUser] = useState<GqlCoreAdminUserFieldsFragment>()

  useEffect(() => {
    ;(async () => {
      try {
        track('admin_user_view')
        await runQuery(async () => {
          setAdminUser(await adminUserView({ userId }))
          setUi('ready')
        })
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        track('admin_user_view_error', { error: error.message })
        setUi('error')
      }
    })()
  }, [])

  if (isUiLoading) {
    return null
  }

  if (isUiError || !adminUser) {
    return <AppErrorPage />
  }

  const { user, accounts, teams, devices } = adminUser

  return (
    <Page>
      <Section className="pt-16 pb-5">
        <div className="w-3/5">
          <div className="flex items-center justify-between">
            <div className="w-full">
              <div className="flex items-center">
                <Text className="text-4xl font-semibold">{utilService.getFullName(user)}</Text>
                {user.status !== 'active' && (
                  <Badge className="ml-4" variant="danger" size="md" state="opaque">
                    <Icon className="mr-1" icon="close" variant="danger" width={8} height={8} /> Inactive
                  </Badge>
                )}
              </div>
              <Badge className="my-12" variant="neutral" size="md" state="opaque">
                {user.isVerified ? (
                  <Icon className="mr-1" icon="check" variant="success" width={11} height={8} />
                ) : (
                  <Icon className="mr-1" icon="close" variant="danger" width={8} height={8} />
                )}{' '}
                Account {user.isVerified ? 'verified' : 'not verified'}
              </Badge>
              <div className="flex items-center justify-between w-full mt-3">
                <Text className="text-sm mr-5" variant="primary-3">
                  ID
                </Text>
                <Text className="font-semibold text-sm" variant="primary-1">
                  {user.id}
                </Text>
              </div>
              <div className="flex items-center justify-between w-full mt-3">
                <Text className="text-sm mr-5" variant="primary-3">
                  Email
                </Text>
                <Text className="font-semibold text-sm" variant="primary-1">
                  {user.email}
                </Text>
              </div>
              <div className="flex items-center justify-between w-full mt-3">
                <Text className="text-sm mr-5" variant="primary-3">
                  Joined
                </Text>
                <Text className="font-semibold text-sm" variant="primary-1">
                  {format(parseISO(user.created_at), DATE_TIME_FORMAT.fullDate)}
                </Text>
              </div>
              <div className="flex items-center justify-between w-full mt-3">
                <Text className="text-sm mr-5" variant="primary-3">
                  App version
                </Text>
                <Text className="font-semibold text-sm" variant="primary-1">
                  {user.appVersion}
                </Text>
              </div>
              <div className="flex items-center justify-between w-full mt-3">
                <Text className="text-sm mr-5" variant="primary-3">
                  Device(s)
                </Text>
                <Text className="font-semibold text-sm" variant="primary-1">
                  {devices.length ? join(devices, ', ') : '–'}
                </Text>
              </div>
            </div>
          </div>

          {Boolean(accounts.length) && (
            <>
              <Text className="text-xl font-semibold mt-8">Accounts</Text>
              {map(accounts, (account, index) => (
                <div key={index} className="border border-ui-800 rounded-lg p-5 mt-2">
                  <div className="flex items-center justify-between">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Sport
                    </Text>
                    <Text className="font-semibold">{upperFirst(find(entities, { id: account.entityId })?.sport || '–')}</Text>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Coaching experience
                    </Text>
                    <Text className="font-semibold">{account.metadata?.experience || '–'}</Text>
                  </div>
                </div>
              ))}
            </>
          )}

          {Boolean(teams.length) && (
            <>
              <Text className="text-xl font-semibold mt-8">Teams</Text>
              {map(teams, (team, index) => (
                <div key={index} className="border border-ui-800 rounded-lg p-5 mt-2">
                  <TeamPill team={team} />
                  <div className="flex items-center justify-between mt-4">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Name
                    </Text>
                    <Text className="font-semibold">{team.name}</Text>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Age group
                    </Text>
                    <Text className="font-semibold">{upperFirst(team.age || '–')}</Text>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Skill level
                    </Text>
                    <Text className="font-semibold">{upperFirst(team.skill || '–')}</Text>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <Text className="text-sm mr-5" variant="primary-3">
                      Team gender
                    </Text>
                    <Text className="font-semibold">{upperFirst(team.gender || '–')}</Text>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <Text className="text-sm mr-5" variant="primary-3">
                      No. players
                    </Text>
                    <Text className="font-semibold">{upperFirst(team.numberOfPlayers || '–')}</Text>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Section>
    </Page>
  )
})
