import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { User } from 'firebase/auth'
import { authService } from './auth.service'
import { clearApolloCache, localStorageService, loggerService } from '@coachmate/common'

export type AuthContextType = {
  firebaseUser: User | null
  isAuthenticated: boolean
  isAuthenticating: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  token: string | null
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [token, setToken] = useState<AuthContextType['token']>(null)
  const [firebaseUser, setFirebaseUser] = useState<AuthContextType['firebaseUser']>(null)

  useEffect(() => {
    authService.onAuthStateChanged(async (firebaseUser) => {
      loggerService.debug('[<AuthProvider />] Firebase User changed.')

      setFirebaseUser(firebaseUser)

      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken())
      } else {
        setToken(null)
      }

      setIsAuthenticating(false)
    })

    authService.onIdTokenChanged(async () => {
      loggerService.debug('[<AuthProvider />] Firebase ID Token changed.')

      // If the token refreshes, we will store the new token in state.
      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken())
      }
    })
  }, [])

  useEffect(() => {
    if (token) {
      localStorageService.setItem('firebase-token', token)
    } else {
      localStorageService.removeItem('firebase-token')
    }
  }, [token])

  const login = async (email: string, password: string) => {
    const firebaseUser = await authService.signInWithEmailAndPassword(email, password)

    setFirebaseUser(firebaseUser)
    setToken(await firebaseUser.getIdToken())
  }

  const logout = async () => {
    await clearApolloCache()
    await authService.signOut()
  }

  const value = useMemo(
    () => ({
      firebaseUser,
      isAuthenticated: Boolean(firebaseUser) && Boolean(token),
      isAuthenticating,
      login,
      logout,
      token,
    }),
    [isAuthenticating, firebaseUser, token]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.')
  }

  return context
}
