import { generatePath } from 'react-router-dom'
import { ExtractRouteParams } from 'react-router'
import { RouterPath } from '@coachmate/router'

class RouterService {
  getHref<S extends RouterPath>(path: S, params?: ExtractRouteParams<S>) {
    // Wrapping this in a try catch, because generatePath will throw an error if the params don't contain values to generate the path from. If this
    // error occurs, we will just default to the root route.
    try {
      return generatePath<S>(path, params)
    } catch {
      return '/'
    }
  }
}

export const routerService = new RouterService()
