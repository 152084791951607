import { map } from 'lodash'
import React, { FormEvent, memo } from 'react'
import { useTag } from '@coachmate/tag'
import { useEntity } from '@coachmate/entity'
import { Button, Input, Label, Select, useCommon } from '@coachmate/common'
import { UserListFilterFormData, USER_LIST_IS_ACTIVE_OPTIONS, USER_LIST_IS_VERIFIED_OPTIONS, useUser } from '@coachmate/user'

type Props = {
  filterFormData: UserListFilterFormData
  setFilterFormData: (formData: UserListFilterFormData) => void
  onFilterSubmit: (event: FormEvent<HTMLFormElement>) => void
  onFilterReset: () => void
  isRequestAccess: boolean
}

export const UserListActions = memo(({ filterFormData, setFilterFormData, onFilterSubmit, onFilterReset, isRequestAccess }: Props) => {
  const { tags } = useTag()
  const { entities } = useEntity()
  const { isSuperuser } = useUser()
  const { isUiLoading, isUiSaving } = useCommon()
  const tagOptions = [{ label: 'All', value: '' }, ...map(tags, ({ id, value }) => ({ label: value, value: id }))]
  const entityOptions = [{ label: 'All', value: '' }, ...map(entities, ({ id, name }) => ({ label: name, value: id }))]

  return (
    <form onSubmit={onFilterSubmit}>
      {isSuperuser && !isRequestAccess && (
        <div className="flex items-center justify-between mb-5">
          <div className="w-full mr-5">
            <Label>Entity</Label>
            <Select
              options={entityOptions}
              onChange={(entityId) => setFilterFormData({ ...filterFormData, entityId })}
              value={filterFormData.entityId}
              isDisabled={isUiSaving || isUiLoading}
            />
          </div>
          <div className="w-full mr-5">
            <Label>Tag</Label>
            <Select
              options={tagOptions}
              onChange={(tagId) => setFilterFormData({ ...filterFormData, tagId })}
              value={filterFormData.tagId}
              isDisabled={isUiSaving || isUiLoading}
            />
          </div>
          <div className="w-full mr-5">
            <Label>Is Verified</Label>
            <Select
              options={USER_LIST_IS_VERIFIED_OPTIONS}
              onChange={(isVerified) => setFilterFormData({ ...filterFormData, isVerified })}
              value={filterFormData.isVerified}
              isDisabled={isUiSaving || isUiLoading}
            />
          </div>
          <div className="w-full">
            <Label>Is Active</Label>
            <Select
              options={USER_LIST_IS_ACTIVE_OPTIONS}
              onChange={(status) => setFilterFormData({ ...filterFormData, status })}
              value={filterFormData.status}
              isDisabled={isUiSaving || isUiLoading}
            />
          </div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="w-full mr-5">
          <Label>First name</Label>
          <Input
            value={filterFormData.firstName}
            onChange={({ target }) => setFilterFormData({ ...filterFormData, firstName: target.value })}
            placeholder="Jane"
            isDisabled={isUiSaving || isUiLoading}
          />
        </div>
        <div className="w-full mr-5">
          <Label>Last name</Label>
          <Input
            value={filterFormData.lastName}
            onChange={({ target }) => setFilterFormData({ ...filterFormData, lastName: target.value })}
            placeholder="Smith"
            isDisabled={isUiSaving || isUiLoading}
          />
        </div>
        <div className="w-full mr-5">
          <Label>Email</Label>
          <Input
            value={filterFormData.email}
            onChange={({ target }) => setFilterFormData({ ...filterFormData, email: target.value })}
            placeholder="jame.smith@email.com"
            isDisabled={isUiSaving || isUiLoading}
          />
        </div>
        <div className="flex items-end justify-end w-full mt-6">
          <Button className="self-end mr-2" variant="primary" type="submit" isDisabled={isUiSaving || isUiLoading}>
            Filter
          </Button>
          <Button className="self-end" onClick={onFilterReset} variant="neutral" state="outline" isDisabled={isUiSaving || isUiLoading}>
            Reset
          </Button>
        </div>
      </div>
    </form>
  )
})
