import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { Button, Icon, Text } from '@coachmate/common'

type Props = {
  className?: string
  team: { id: string; name: string; colour: string }
  href?: string
  size?: 'sm' | 'md'
  onClick?: () => void
  isDropdown?: boolean
  isDisabled?: boolean
  isSelected?: boolean
}

export const TeamPill = ({ className, team, href, size = 'md', onClick, isDropdown, isDisabled, isSelected }: Props) => {
  const history = useHistory()
  const classes = classNames(className, 'inline-flex items-center justify-between bg-white bg-opacity-10 rounded-full p-1 pr-3', {
    'border border-white border-opacity-60': isDropdown,
    'outline-none': href || onClick,
    'pointer-events-none opacity-50': isDisabled,
    'max-w-4xs': size === 'sm',
    'max-w-3xs': size === 'md',
  })
  const circleClasses = classNames('shrink-0 inline-flex items-center justify-center rounded-full mr-2', {
    'w-4 h-4': size === 'sm',
    'w-6 h-6': size === 'md',
  })
  const textClasses = classNames('whitespace-nowrap overflow-hidden overflow-ellipsis', {
    'text-xs': size === 'sm',
    'font-semibold': size === 'md',
  })

  const renderContent = () => (
    <>
      <div className={circleClasses} style={{ backgroundColor: team.colour }}>
        {isSelected && <Icon className="absolute -ml-px" icon="check" variant="primary-1" width={13} height={10} />}
      </div>
      <Text className={textClasses} variant="primary-1">
        {team.name}
      </Text>
      {isDropdown && <Icon className="ml-2" icon="chevron-down" variant="primary-2" width={12} height={6} />}
    </>
  )

  if (href || onClick) {
    const handleLinkClick = () => {
      href && history.push(href)
      onClick && onClick()
    }

    return (
      <Button state="raw" className={classes} onClick={handleLinkClick}>
        {renderContent()}
      </Button>
    )
  }

  return <div className={classes}>{renderContent()}</div>
}
