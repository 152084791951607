import { forEach } from 'lodash'
import React, { memo } from 'react'
import { GqlAdminOverviewQuery } from '@graphql'
import { OverviewBreakdownBars, OverviewCard } from '@coachmate/overview'

type Props = {
  overview: GqlAdminOverviewQuery['adminOverview']
}

export const OverviewSectionTeamRole = memo(({ overview }: Props) => {
  const teamRoles = [
    { label: 'Coach', key: 'coach', count: 0, percentage: 0 },
    { label: 'Caregiver', key: 'parent', count: 0, percentage: 0 },
    { label: 'Player', key: 'player', count: 0, percentage: 0 },
    { label: 'Supporter', key: 'supporter', count: 0, percentage: 0 },
    { label: 'Teacher', key: 'teacher', count: 0, percentage: 0 },
    { label: 'Team manager', key: 'teamManager', count: 0, percentage: 0 },
  ]

  let totalTeamRoleCount = 0
  forEach(overview.teamRoles, ({ count }) => {
    totalTeamRoleCount += count
  })

  forEach(overview.teamRoles, ({ teamRole, count }) => {
    //This handles old 'other' role as there is still data for this
    const role = teamRole === 'other' ? 'supporter' : teamRole
    const roleData = teamRoles.find(({ key }) => key === role)
    if (roleData) {
      roleData.count = count
      roleData.percentage = (count / totalTeamRoleCount) * 100
    }
  })

  return (
    <OverviewCard className="mt-5" heading="Team Role">
      <OverviewBreakdownBars className="mt-5" breakdown={teamRoles} />
    </OverviewCard>
  )
})
