import classNames from 'classnames'
import React, { ReactNode, HTMLProps, memo } from 'react'

type Props = HTMLProps<HTMLTableElement> & {
  children: ReactNode
  className?: string
}

export const Table = memo(({ className, children, ...rest }: Props) => (
  <table {...rest} className={classNames(className, 'divide-y divide-ui-800 w-full border-collapse')}>
    {children}
  </table>
))
