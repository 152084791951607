import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { GqlByUserIdInput, GqlSuperuserVerifyUserMutation, GqlSuperuserVerifyUserMutationVariables } from '@graphql'

const SUPERUSER_VERIFY_USER = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  mutation SuperuserVerifyUser($input: ByUserIdInput!) {
    superuserVerifyUser(input: $input) {
      ...CoreUserFields
    }
  }
`

export const superuserVerifyUser = async (input: GqlByUserIdInput) => {
  loggerService.debug('[superuserVerifyUser] Verifying user.')

  const { data } = await apolloService.mutate<GqlSuperuserVerifyUserMutation, GqlSuperuserVerifyUserMutationVariables>({
    mutation: SUPERUSER_VERIFY_USER,
    variables: { input },
  })

  return data?.superuserVerifyUser
}
