import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { TAG_CORE_FIELDS_FRAGMENT } from '@coachmate/tag/graphql/tag-fragment.graphql'
import { GqlCreateTagInput, GqlCreateTagMutation, GqlCreateTagMutationVariables } from '@graphql'

const CREATE_TAG = gql`
  ${TAG_CORE_FIELDS_FRAGMENT}

  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ...CoreTagFields
    }
  }
`

export const createTag = async (input: GqlCreateTagInput) => {
  loggerService.debug('[createTag] Creating tag.')

  const { data } = await apolloService.mutate<GqlCreateTagMutation, GqlCreateTagMutationVariables>({
    mutation: CREATE_TAG,
    variables: { input },
  })

  return data?.createTag
}
