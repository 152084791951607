import { gql } from '@apollo/client'

export const PAGINATION_CORE_FIELDS_FRAGMENT = gql`
  fragment CorePaginationFields on Pagination {
    page
    pageSize
    pageCount
    itemCount
    totalCount
  }
`
