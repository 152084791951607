import { filter, find, map } from 'lodash'
import React, { FormEvent, useEffect, useState } from 'react'
import { useUser } from '@coachmate/user'
import { TagSelect, updateUserTags, useTag } from '@coachmate/tag'
import { GqlCoreAdminUserFieldsFragment, GqlCoreTagFieldsFragment } from '@graphql'
import { Button, ERROR_MESSAGE, Section, sentryService, Text, useAnalytics, useModal, useRunQuery, useToast } from '@coachmate/common'

type Props = {
  adminUser: GqlCoreAdminUserFieldsFragment
  refetch: () => void
}

export const ManageUserTagsModal = ({ adminUser, refetch }: Props) => {
  const { tags } = useTag()
  const runQuery = useRunQuery()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const { isSuperuser } = useUser()
  const [isSaving, setIsSaving] = useState(false)
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>(adminUser.tags)

  useEffect(() => {
    track('admin_user_manage_tags_modal_open')

    if (!isSuperuser) {
      closeModal()
      openToast(<Text>{ERROR_MESSAGE.permission}</Text>, 'danger')
      return
    }
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('admin_user_manage_tags_submit', { userId: adminUser.user.id })
    setIsSaving(true)

    try {
      await runQuery(async () => {
        await updateUserTags({ userId: adminUser.user.id, tagIds: selectedTagIds })
        refetch()
        closeModal()
      })
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      track('admin_user_manage_tags_submit_error', { userId: adminUser.user.id, error: error.message })
      sentryService.captureException({ exception: error, extras: { userId: adminUser.user.id } })
    }
  }

  const handleClick = (tag: GqlCoreTagFieldsFragment) => {
    if (find(selectedTagIds, (tagId) => tagId === tag.id)) {
      setSelectedTagIds(filter(selectedTagIds, (tagId) => tagId !== tag.id))
    } else {
      setSelectedTagIds([...selectedTagIds, tag.id])
    }
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Text className="font-semibold text-lg mb-4">Tags</Text>
        {map(tags, (tag) => (
          <TagSelect
            key={tag.id}
            className="m-1"
            tag={tag}
            onClick={handleClick}
            isSelected={Boolean(find(selectedTagIds, (tagId) => tagId === tag.id))}
          />
        ))}
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" isLoading={isSaving}>
            Update Tags
          </Button>
        </div>
      </Section>
    </form>
  )
}
