import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { GqlAdminOverviewInput, GqlAdminOverviewQuery, GqlAdminOverviewQueryVariables } from '@graphql'

export const ADMIN_OVERVIEW = gql`
  query AdminOverview($input: AdminOverviewInput!) {
    adminOverview(input: $input) {
      userCount
      coachCount

      userGenders {
        gender
        count
      }
      userIntentions {
        intention
        count
      }
      coachingExperiences {
        experience
        count
      }
      coachingReasons {
        reason
        count
      }

      teamCount
      teamPlayerCounts {
        numberOfPlayers
        count
      }
      teamGenders {
        gender
        count
      }
      teamAges {
        age
        count
      }
      teamSkills {
        skill
        count
      }
      teamClubOrganisations {
        clubOrganisation
        count
      }
      teamRoles {
        teamRole
        count
      }

      eventsCreated
      eventsCreatedPast
      eventsCreatedFuture
      practiceEventsCreated
      practiceEventsCreatedPast
      practiceEventsCreatedFuture
      gameEventsCreated
      gameEventsCreatedPast
      gameEventsCreatedFuture
      customEventsCreated
      customEventsCreatedPast
      customEventsCreatedFuture
      practicePlansBooked
      practicePlansBookedPast
      practicePlansBookedFuture

      customPlansCreated
      drillRatingBreakdown {
        name
        average
      }
      practicePlanRatingBreakdown {
        name
        average
      }
      practicePlansBookedBreakdown {
        name
        count
      }
    }
  }
`

export const adminOverview = async (input: GqlAdminOverviewInput) => {
  loggerService.debug('[adminOverview] Get admin overview.')

  const { data } = await apolloService.query<GqlAdminOverviewQuery, GqlAdminOverviewQueryVariables>({
    query: ADMIN_OVERVIEW,
    variables: { input },
    fetchPolicy: 'network-only',
  })

  return data.adminOverview
}
