import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { GqlByUserIdInput, GqlSuperuserDisableUserMutation, GqlSuperuserDisableUserMutationVariables } from '@graphql'

const SUPERUSER_DISABLE_USER = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  mutation SuperuserDisableUser($input: ByUserIdInput!) {
    superuserDisableUser(input: $input) {
      ...CoreUserFields
    }
  }
`

export const superuserDisableUser = async (input: GqlByUserIdInput) => {
  loggerService.debug('[superuserDisableUser] Disabling user.')

  const { data } = await apolloService.mutate<GqlSuperuserDisableUserMutation, GqlSuperuserDisableUserMutationVariables>({
    mutation: SUPERUSER_DISABLE_USER,
    variables: { input },
  })

  return data?.superuserDisableUser
}
