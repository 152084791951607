import React, { FormEvent, useEffect, useState } from 'react'
import { GqlCoreTagFieldsFragment } from '@graphql'
import { Tag, tags, updateTag, useTag } from '@coachmate/tag'
import { Button, Section, sentryService, Text, useAnalytics, useModal, useToast } from '@coachmate/common'

type Props = {
  tag: GqlCoreTagFieldsFragment
}

export const ArchiveTagModal = ({ tag }: Props) => {
  const { setTags } = useTag()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useModal()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    track('admin_tag_archive_modal_open')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('admin_tag_archive_submit', { tagId: tag.id })
    setIsSaving(true)

    try {
      await updateTag({ tagId: tag.id, status: 'inactive' })
      const { items } = await tags({ pagination: { page: 1, pageSize: 100 }, sort: { sortBy: 'created_at', direction: 'desc' } })

      setTags(items)
      closeModal()
    } catch (error: any) {
      setIsSaving(false)
      openToast(<Text>Something went wrong</Text>, 'danger')
      sentryService.captureException({ exception: error, extras: { tagId: tag.id } })
      track('admin_tag_archive_submit_error', { tagId: tag.id, error: error.message })
    }
  }

  return (
    <form className="w-[600px] max-w-sm" onSubmit={handleSubmit}>
      <Section className="py-5">
        <Text>
          Are you sure you want to archive <Tag tag={tag} />?
        </Text>
        <div className="flex items-center justify-end mt-4">
          <Button className="mr-3" onClick={closeModal} variant="neutral" state="outline" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="danger" isLoading={isSaving}>
            Archive
          </Button>
        </div>
      </Section>
    </form>
  )
}
