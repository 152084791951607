import { map } from 'lodash'
import * as Sentry from '@sentry/react'
import { Switch, useHistory } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { Route } from '@coachmate/router'
import { AppLayout, AppRoute } from '@coachmate/app'
import { AUTH_PATHS, AuthLayout } from '@coachmate/auth'
import { FiveHundredPage, sentryService, useAnalytics, useCommon } from '@coachmate/common'

export const App = Sentry.withProfiler(() => {
  const route = useRef(location.pathname)
  const history = useHistory()
  const { setUi } = useCommon()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { init: initAnalytics, track } = useAnalytics()

  useEffect(() => {
    ;(async () => {
      try {
        history.listen((event, action) => {
          window.scroll(0, 0)

          // Only set the page state to loading on a `PUSH` history event, and if the previous route does not match the new path.
          if (action === 'PUSH' && route.current !== event.pathname) {
            setUi('loading')
          }

          route.current = event.pathname
        })

        sentryService.init()
        initAnalytics()
        track('admin_app_load')
        setIsLoading(false)
      } catch (error: any) {
        setIsError(true)
        setIsLoading(false)
        track('admin_app_load_error')
        sentryService.captureException({ exception: error })
      }
    })()
  }, [])

  if (isLoading) {
    return null
  }

  if (isError) {
    return <FiveHundredPage />
  }

  return (
    <Switch>
      <Route path="/error" component={FiveHundredPage} exact />
      {map(AUTH_PATHS, (path) => (
        <Route key={path} path={path} component={AuthLayout} exact />
      ))}
      <AppRoute path="/" component={AppLayout} />
    </Switch>
  )
})
