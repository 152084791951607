import React, { memo } from 'react'
import { GqlPagination } from '@graphql'
import { Button, Icon, paginationService, Td, Text, Tr, useCommon } from '@coachmate/common'

type Props = {
  page: number
  pagination: GqlPagination
  onPageChange: (page: number) => void
  colSpan: number
}

export const ListPagination = memo(({ page, pagination, onPageChange, colSpan }: Props) => {
  const { isUiSaving } = useCommon()

  return (
    <Tr>
      <Td colSpan={colSpan}>
        <div className="flex items-center justify-between">
          <Text>{paginationService.getDisplayValue(pagination)}</Text>
          <div>
            <Button onClick={() => onPageChange(1)} variant="primary" shape="square" state="outline" isDisabled={isUiSaving || pagination.page < 2}>
              <Icon icon="chevron-left" width={7} height={14} />
              <Icon icon="chevron-left" width={7} height={14} />
            </Button>
            <Button
              className="ml-2"
              onClick={() => onPageChange(page - 1)}
              variant="primary"
              shape="square"
              state="outline"
              isDisabled={isUiSaving || pagination.page < 2}
            >
              <Icon icon="chevron-left" width={7} height={14} />
            </Button>
            <Button
              className="ml-2"
              onClick={() => onPageChange(page + 1)}
              variant="primary"
              shape="square"
              state="outline"
              isDisabled={isUiSaving || pagination.page === pagination.pageCount}
            >
              <Icon icon="chevron-right" width={7} height={14} />
            </Button>
            <Button
              className="ml-2"
              onClick={() => onPageChange(pagination.pageCount)}
              variant="primary"
              shape="square"
              state="outline"
              isDisabled={isUiSaving || pagination.page === pagination.pageCount}
            >
              <Icon icon="chevron-right" width={7} height={14} />
              <Icon icon="chevron-right" width={7} height={14} />
            </Button>
          </div>
        </div>
      </Td>
    </Tr>
  )
})
