import { gql } from '@apollo/client'

export const TEAM_CORE_FIELDS_FRAGMENT = gql`
  fragment CoreTeamFields on Team {
    id
    name
    colour
    programmeId
    age
    skill
    gender
    numberOfPlayers
  }
`
