import classNames from 'classnames'
import { find, indexOf, map } from 'lodash'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { TabsVariant, useTabs } from '@coachmate/common'

type Props<T> = {
  className?: string
  id: T
  label: ReactNode
  variant?: TabsVariant
  isDisabled?: boolean
}

const BASE_CLASSES = ['flex', 'items-center', 'focus:outline-none']
const BASE_PILL_CLASSES = ['text-sm', 'rounded-full', 'h-7', 'text-white', 'text-opacity-90', 'px-3', 'z-1']
const BASE_UNDERLINE_CLASSES = ['text-sm', 'border-b-4', 'border-transparent', 'pb-2', 'mr-6', 'last:mr-0']

function TabButtonInner<T>({ className, id, label, variant, isDisabled }: Props<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const { activeTab, setActiveTab, tabs } = useTabs()
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id)

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`)
  }

  const isActive = activeTab.id === id

  let classes = classNames(className, BASE_CLASSES, {
    'is-active': isActive,
    'pointer-events-none opacity-50': isDisabled,
  })

  if (variant === 'square-pill' || variant === 'rounded-pill') {
    classes = classNames(classes, BASE_PILL_CLASSES, {
      'mr-1': indexOf(tabs, tab) !== tabs.length - 1,
    })
  }

  if (variant === 'underline') {
    classes = classNames(classes, BASE_UNDERLINE_CLASSES, {
      'text-white text-opacity-30': !isActive,
      'text-white': isActive,
    })
  }

  const handleClick = () => {
    // If there are only 2 tabs, always switch the tab.
    setActiveTab(tabs.length === 2 ? find(tabs, (tabToActivate) => tabToActivate.id !== activeTab.id) || tab : tab)
  }

  return (
    <button className={classes} onClick={handleClick} type="button" disabled={isDisabled} ref={ref}>
      {/* Wrapping the content in a div, so we can get the exact width of the content without having to hard code the padding offset. */}
      <div>{label}</div>
    </button>
  )
}

export const TabButton = forwardRef(TabButtonInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TabButtonInner>
