import { gql } from '@apollo/client'
import { apolloService, loggerService } from '@coachmate/common'
import { USER_CORE_FIELDS_FRAGMENT } from '@coachmate/user/graphql/user-fragment.graphql'
import { GqlUpdateUserInput, GqlUpdateUserMutation, GqlUpdateUserMutationVariables } from '@graphql'

const UPDATE_USER = gql`
  ${USER_CORE_FIELDS_FRAGMENT}

  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...CoreUserFields
    }
  }
`

export const updateUser = async (input: GqlUpdateUserInput) => {
  loggerService.debug('[updateUser] Updating user.')

  const { data } = await apolloService.mutate<GqlUpdateUserMutation, GqlUpdateUserMutationVariables>({
    mutation: UPDATE_USER,
    variables: { input },
  })

  return data?.updateUser
}
