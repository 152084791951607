import React, { memo } from 'react'
import { Td, Tr, TextSkeleton } from '@coachmate/common'

export const TagListRowSkeleton = memo(() => (
  <Tr>
    <Td>
      <TextSkeleton className="h-4 w-20" />
    </Td>
    <Td>
      <TextSkeleton className="h-5 w-20" />
    </Td>
    <Td>&nbsp;</Td>
  </Tr>
))
