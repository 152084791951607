import React, { useState, useCallback, memo, ReactNode, useContext, useMemo, createContext } from 'react'
import { ToastNotification, ToastProps, ToastVariant } from '@coachmate/common'

export type ToastContext = {
  openToast(content: ReactNode, variant?: ToastVariant, className?: string): void
  closeToast(): void
}

const ToastContext = createContext<ToastContext | null>(null)

// Auto dismiss toasts after 3 seconds.
const TOAST_DISMISS_TIMEOUT = 3000

type Props = {
  children: ReactNode
}

// @TODO handle multiple toasts
export const ToastProvider = memo(({ children }: Props) => {
  const [toast, setToast] = useState<ToastProps | null>(null)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const openToast = useCallback(
    (children: ReactNode, variant: ToastVariant = 'success', className: string) => {
      clearTimeout(timer as NodeJS.Timeout)

      setToast({ children, variant, className })

      if (variant === 'success') {
        const timer = setTimeout(() => {
          setToast(null)
        }, TOAST_DISMISS_TIMEOUT)

        setTimer(timer)
      }
    },
    [timer]
  )

  const closeToast = useCallback(() => {
    clearTimeout(timer as NodeJS.Timeout)
    setToast(null)
  }, [timer])

  const renderToast = () => {
    if (!toast) {
      return null
    }

    const { children, variant = 'success', className } = toast

    return (
      <ToastNotification className={className} variant={variant}>
        {children}
      </ToastNotification>
    )
  }

  const memoizedContextValue = useMemo(
    () => ({
      openToast,
      closeToast,
    }),
    [openToast]
  )

  return (
    <ToastContext.Provider value={memoizedContextValue}>
      {children}
      {renderToast()}
    </ToastContext.Provider>
  )
})

export const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('This component must be used within a <ToastProvider> component.')
  }

  return context
}
